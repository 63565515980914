import React, { useState } from "react";
import Toggle from "../../Toggle";

function Card() {
  const [isShown, setIsShown] = useState(false);
  const [isShownToggle, setIsShownToggle] = useState(false);
  
  return (
    <div className="bg-white rounded-md shadow">
        <div className="h-1 bg-indigo-500 rounded-t-md"></div>
        <div className="px-5 py-6 space-y-4">
            <h2 className="text-xl font-medium text-gray-800">Office Hours</h2>

            <div className="flex items-center space-x-2 text-gray-600">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M10 17.5C5.858 17.5 2.5 14.142 2.5 10C2.5 5.85775 5.858 2.5 10 2.5C14.142 2.5 17.5 5.85775 17.5 10C17.5 14.142 14.142 17.5 10 17.5ZM10 3.5C6.41025 3.5 3.5 6.41025 3.5 10C3.5 13.5898 6.41025 16.5 10 16.5C13.5898 16.5 16.5 13.5898 16.5 10C16.5 6.41025 13.5898 3.5 10 3.5ZM10 15.5C6.9625 15.5 4.5 13.0375 4.5 10H10C10 7.61 10 5.2715 10 4.5C13.0375 4.5 15.5 6.9625 15.5 10C15.5 13.0375 13.0375 15.5 10 15.5Z" fill="#565759"/>
                </svg>
                <span>45 mins</span>
            </div>

            <div 
                onMouseEnter={() => setIsShown(true)}
                onMouseLeave={() => setIsShown(false)} 
                className="relative inline-flex items-center space-x-2 text-gray-600 cursor-pointer"
            >
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M7.99967 14.6668C11.6816 14.6668 14.6663 11.6821 14.6663 8.00016C14.6663 4.31826 11.6816 1.3335 7.99967 1.3335C4.31778 1.3335 1.33301 4.31826 1.33301 8.00016C1.33301 11.6821 4.31778 14.6668 7.99967 14.6668Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M6.05957 5.99989C6.21631 5.55434 6.52567 5.17863 6.93287 4.93931C7.34007 4.7 7.81883 4.61252 8.28435 4.69237C8.74987 4.77222 9.17211 5.01424 9.47629 5.37558C9.78046 5.73691 9.94694 6.19424 9.94624 6.66656C9.94624 7.99989 7.94624 8.66656 7.94624 8.66656" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M8 11.3335H8.00667" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>

                <span>Weekly 5 - 6PM</span>

                {isShown && (
                    <div className="absolute z-10 w-56 px-3 py-6 bg-black rounded-lg cursor-default top-8 -left-12">
                        <p className="flex items-center justify-between">
                            <span className="text-sm text-white">Monday</span>
                            <span className="text-sm text-gray-400">2:00 - 2:30 PM</span>
                        </p>

                        <hr className="my-3 border-gray-700" />

                        <p className="flex items-center justify-between">
                            <span className="text-sm text-white">Monday</span>
                            <span className="text-sm text-gray-400">2:00 - 2:30 PM</span>
                        </p>

                        <hr className="my-3 border-gray-700" />

                        <p className="flex items-center justify-between">
                            <span className="text-sm text-white">Monday</span>
                            <span className="text-sm text-gray-400">2:00 - 2:30 PM</span>
                        </p>
                    </div>
                )}
            </div>
        </div>

        <hr />

        <div className="flex items-center justify-between px-5 py-4">
            <label className="relative flex items-center space-x-2 text-gray-600">
                <Toggle />
                <span className="cursor-pointer " onMouseEnter={() => setIsShownToggle(true)}
                    onMouseLeave={() => setIsShownToggle(false)} >
                    Available
                </span>

                {isShownToggle && (
                    <div className="absolute z-10 w-56 px-3 py-6 bg-black rounded-lg cursor-default top-8 -left-12">
                        <h2 className="text-sm text-white">Currently patients can’t book this event</h2>
                        <p className="mt-2 text-sm text-gray-400">If you enable this event, patients can book this event.</p>
                    </div>
                )}
            </label>

            <button className="flex items-center space-x-2 text-gray-500 hover:text-gray-600">
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.5">
                <path d="M2 4H3.33333H14" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M5.33301 4.00016V2.66683C5.33301 2.31321 5.47348 1.97407 5.72353 1.72402C5.97358 1.47397 6.31272 1.3335 6.66634 1.3335H9.33301C9.68663 1.3335 10.0258 1.47397 10.2758 1.72402C10.5259 1.97407 10.6663 2.31321 10.6663 2.66683V4.00016M12.6663 4.00016V13.3335C12.6663 13.6871 12.5259 14.0263 12.2758 14.2763C12.0258 14.5264 11.6866 14.6668 11.333 14.6668H4.66634C4.31272 14.6668 3.97358 14.5264 3.72353 14.2763C3.47348 14.0263 3.33301 13.6871 3.33301 13.3335V4.00016H12.6663Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.66699 7.3335V11.3335" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9.33301 7.3335V11.3335" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                </g>
                </svg>
                <span>Delete</span>
            </button>
        </div>
    </div>
  );
}

export default Card;
