import React from "react";

function MultiDoctors() {
  return (
    <div className="min-h-screen flex flex-col bg-primary lg:bg-[#F5F5F5] lg:flex-row font-dm-sans">
      <section className="overflow-y-auto lg:w-2/3">
        <div className="py-8 lg:hidden">
          <div className="flex justify-center">
            <div className="flex items-center space-x-4">
                <span className="p-2 text-white lg:p-2.5 rounded-xl bg-white/10">
                  <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.00019 15.7997C12.7557 15.7997 15.8002 12.7552 15.8002 8.99971C15.8002 5.24417 12.7557 2.19971 9.00019 2.19971C5.24466 2.19971 2.2002 5.24417 2.2002 8.99971C2.2002 12.7552 5.24466 15.7997 9.00019 15.7997Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M9 4.91992V8.99992L11.72 10.3599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>

              <div className="font-bold ">
                  <p className="text-sm text-white/30">Open</p>
                  <p className="text-sm text-white">Scheduling</p>
              </div>
            </div>
          </div>
        </div>
        
        <div className="w-full px-8 py-12 mx-auto bg-white lg:bg-transparent rounded-t-2xl lg:h-screen 2xl:px-16">
          <p className="text-sm font-bold text-gray-600 uppercase">Book your slot</p>

          <h2 className="mt-2 text-2xl font-bold text-gray-800">Sure Smile Dental Care</h2>

          <main className="mt-20">
            <section className="hidden xl:flex xl:space-x-8">
              <div className="text-lg font-bold text-gray-800 lg:w-1/4">
                30 Doctors
              </div>

              <div className="items-center space-x-8 lg:w-3/4 lg:flex">
                <button className="text-gray-800 focus:outline-none">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11.25 13.5L6.75 9L11.25 4.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>

                <section className="grid flex-1 grid-cols-5 gap-8">
                  <p className="text-sm text-gray-800 2xl:text-base ">Wed <span className="font-bold ">Feb 16</span></p>
                  <p className="text-sm text-gray-800 2xl:text-base ">Wed <span className="font-bold ">Feb 16</span></p>
                  <p className="text-sm text-gray-800 2xl:text-base ">Wed <span className="font-bold ">Feb 16</span></p>
                  <p className="text-sm text-gray-800 2xl:text-base ">Wed <span className="font-bold ">Feb 16</span></p>
                  <p className="text-sm text-gray-800 2xl:text-base ">Wed <span className="font-bold ">Feb 16</span></p>
                </section>

                <button className="text-gray-800 focus:outline-none">
                  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M6.75 13.5L11.25 9L6.75 4.5" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>
              </div>
            </section>
            <section className="space-y-8 lg:mt-6">
              <div className="lg:flex lg:space-x-8">
                <div className="lg:w-1/4 ">
                  <div className="flex items-center space-x-4 ">
                    <img className="object-cover rounded-full shrink-0 w-14 h-14 " src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="" />
                    <div>
                      <h3 className="text-xl font-bold text-gray-800">Dr. Michelle Frank</h3>
                      <p className="mt-1 text-sm text-gray-600">michellefrank@gmail.com</p>
                    </div>
                  </div>

                  <p className="mt-10 text-sm text-gray-500 ">About Michelle</p>

                  <hr className="my-4 border-gray-200 " />

                  <h3 className="text-sm text-gray-800 ">Child Specialist</h3>

                  <p className="mt-2 text-gray-500 ">5 Years of Experience</p>
                </div>
                
                <div className="grid grid-cols-3 gap-6 mt-6 bg-white lg:mt-0 xl:grid-cols-4 2xl:grid-cols-5 lg:p-8 lg:w-3/4 rounded-xl ">
                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      07:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      07:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>
                  </div>

                  <div className="hidden space-y-4 xl:flex xl:flex-col ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>
                  </div>

                  <div className="hidden space-y-4 2xl:flex 2xl:flex-col ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>
                  </div>
                </div>
              </div>
              
              <div className="lg:flex lg:space-x-8">
                <div className="lg:w-1/4 ">
                  <div className="flex items-center space-x-4 ">
                    <img className="object-cover rounded-full shrink-0 w-14 h-14 " src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="" />
                    <div>
                      <h3 className="text-xl font-bold text-gray-800">Dr. Michelle Frank</h3>
                      <p className="mt-1 text-sm text-gray-600">michellefrank@gmail.com</p>
                    </div>
                  </div>

                  <p className="mt-10 text-sm text-gray-500 ">About Michelle</p>

                  <hr className="my-4 border-gray-200 " />

                  <h3 className="text-sm text-gray-800 ">Child Specialist</h3>

                  <p className="mt-2 text-gray-500 ">5 Years of Experience</p>
                </div>
                
                <div className="grid grid-cols-3 gap-6 mt-6 bg-white lg:mt-0 xl:grid-cols-4 2xl:grid-cols-5 lg:p-8 lg:w-3/4 rounded-xl ">
                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      07:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      07:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>
                  </div>

                  <div className="hidden space-y-4 xl:flex xl:flex-col ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>
                  </div>

                  <div className="hidden space-y-4 2xl:flex 2xl:flex-col ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>
                  </div>
                </div>
              </div>

              <div className="lg:flex lg:space-x-8">
                <div className="lg:w-1/4 ">
                  <div className="flex items-center space-x-4 ">
                    <img className="object-cover rounded-full shrink-0 w-14 h-14 " src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="" />
                    <div>
                      <h3 className="text-xl font-bold text-gray-800">Dr. Michelle Frank</h3>
                      <p className="mt-1 text-sm text-gray-600">michellefrank@gmail.com</p>
                    </div>
                  </div>

                  <p className="mt-10 text-sm text-gray-500 ">About Michelle</p>

                  <hr className="my-4 border-gray-200 " />

                  <h3 className="text-sm text-gray-800 ">Child Specialist</h3>

                  <p className="mt-2 text-gray-500 ">5 Years of Experience</p>
                </div>
                
                <div className="grid grid-cols-3 gap-6 mt-6 bg-white lg:mt-0 xl:grid-cols-4 2xl:grid-cols-5 lg:p-8 lg:w-3/4 rounded-xl ">
                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      07:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      07:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>
                  </div>

                  <div className="hidden space-y-4 xl:flex xl:flex-col ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>
                  </div>

                  <div className="hidden space-y-4 2xl:flex 2xl:flex-col ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>
                  </div>
                </div>
              </div>

              <div className="lg:flex lg:space-x-8">
                <div className="lg:w-1/4 ">
                  <div className="flex items-center space-x-4 ">
                    <img className="object-cover rounded-full shrink-0 w-14 h-14 " src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=687&q=80" alt="" />
                    <div>
                      <h3 className="text-xl font-bold text-gray-800">Dr. Michelle Frank</h3>
                      <p className="mt-1 text-sm text-gray-600">michellefrank@gmail.com</p>
                    </div>
                  </div>

                  <p className="mt-10 text-sm text-gray-500 ">About Michelle</p>

                  <hr className="my-4 border-gray-200 " />

                  <h3 className="text-sm text-gray-800 ">Child Specialist</h3>

                  <p className="mt-2 text-gray-500 ">5 Years of Experience</p>
                </div>
                
                <div className="grid grid-cols-3 gap-6 mt-6 bg-white lg:mt-0 xl:grid-cols-4 2xl:grid-cols-5 lg:p-8 lg:w-3/4 rounded-xl ">
                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      07:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      07:00 PM
                    </button>
                  </div>

                  <div className="flex flex-col space-y-4 ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>
                  </div>

                  <div className="hidden space-y-4 xl:flex xl:flex-col ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      06:00 PM
                    </button>
                  </div>

                  <div className="hidden space-y-4 2xl:flex 2xl:flex-col ">
                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      04:00 PM
                    </button>

                    <button className="px-4 py-2 text-sm text-gray-800 transition-colors duration-200 border border-gray-200 rounded-lg focus:outline-none hover:bg-gray-800 hover:text-white">
                      05:00 PM
                    </button>
                  </div>
                </div>
              </div>
            </section>
          </main>
        </div>
      </section>

      <section className="relative items-end hidden w-full lg:flex lg:w-1/3 bg-primary">
        <svg className="absolute right-0 -translate-y-1/2 top-1/2 opacity-20" width="426" height="516" viewBox="0 0 426 516" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M224.779 191.557L282.913 141.728L224.779 91.8981V116.813H133.426V166.643H224.779V191.557Z" fill="white"/>
          <path d="M374.275 17.157C418.778 17.1657 459.897 40.9192 482.142 79.4648C504.386 118.01 504.382 165.494 482.124 204.033C459.866 242.57 418.738 266.31 374.235 266.305C329.727 266.301 288.608 242.552 266.359 204.006L251.987 212.328C272.925 248.597 308.889 273.697 350.153 280.843C391.421 287.989 433.728 276.45 465.653 249.339C497.57 222.228 515.808 182.345 515.434 140.468C515.061 98.5908 496.113 59.0406 463.714 32.504C431.316 5.97072 388.812 -4.81386 347.676 3.06834C306.544 10.951 271.036 36.6858 250.747 73.3246L265.263 81.3871V81.3827C276.057 61.9303 291.853 45.7181 311.021 34.424C330.189 23.1298 352.03 17.168 374.275 17.1545L374.275 17.157Z" fill="white"/>
          <path d="M432.4 233.084V66.9889C432.4 64.7881 431.528 62.6748 429.967 61.1141C428.411 59.5578 426.298 58.6853 424.097 58.6853H324.436C319.851 58.6853 316.133 62.403 316.133 66.9888V233.084H357.659L357.655 191.562H390.874V233.088L432.4 233.084ZM349.352 166.647H332.74V150.035H349.352V166.647ZM349.352 133.428H332.74V116.816H349.352V133.428ZM349.352 100.209L332.74 100.205V83.5974H349.352V100.209ZM382.571 166.65L365.963 166.646V150.034H382.575L382.571 166.65ZM382.571 133.431L365.963 133.427V116.815H382.575L382.571 133.431ZM382.571 100.212L365.963 100.204V83.5964H382.575L382.571 100.212ZM399.182 83.6006H415.794V100.212L399.182 100.204V83.6006ZM399.182 116.82H415.794V133.431L399.182 133.427V116.82ZM399.182 150.039H415.794V166.65L399.182 166.646V150.039Z" fill="white"/>
          <path d="M8.85165 515.454H274.611C276.811 515.458 278.925 514.581 280.485 513.025C282.042 511.464 282.914 509.355 282.914 507.15V274.612C282.91 268.005 280.279 261.675 275.61 257.002C270.941 252.333 264.606 249.706 258 249.698H233.085V241.394C233.089 239.189 232.212 237.076 230.656 235.519C229.095 233.963 226.986 233.086 224.781 233.086H191.562C189.357 233.086 187.244 233.963 185.687 235.519C184.131 237.076 183.254 239.189 183.254 241.394V249.698H100.205V241.394C100.205 239.189 99.333 237.076 97.7766 235.519C96.2159 233.963 94.1027 233.086 91.9018 233.086H58.6828C56.4776 233.086 54.3644 233.963 52.808 235.519C51.2517 237.076 50.3748 239.189 50.3748 241.394V249.698H25.4599C18.8574 249.706 12.5227 252.333 7.85266 257.002C3.17912 261.675 0.553066 268.006 0.544312 274.612V507.15C0.544312 509.355 1.42114 511.464 2.97755 513.025C4.53389 514.581 6.64704 515.458 8.85233 515.454H8.85165ZM199.863 249.695H216.475V274.61H199.863V249.695ZM66.9889 249.695H83.6006V274.61H66.9889V249.695ZM17.1592 307.829H266.308V498.851H17.1592V307.829Z" fill="white"/>
          <path d="M37.9203 336.901H96.0541V395.034H37.9203V336.901Z" fill="white"/>
          <path d="M166.653 334.824H116.823C113.381 334.824 110.593 337.613 110.593 341.054V390.884C110.593 394.321 113.381 397.109 116.823 397.109H166.653C170.09 397.109 172.878 394.321 172.883 390.884V341.054C172.878 337.613 170.09 334.824 166.653 334.824ZM123.052 347.28H151.614L123.052 375.847V347.28ZM160.423 384.65L131.86 384.655L160.423 356.088V384.65Z" fill="white"/>
          <path d="M241.389 334.824H191.56C188.118 334.824 185.33 337.613 185.33 341.054V390.884C185.33 394.321 188.118 397.109 191.56 397.109H241.389C244.827 397.109 247.615 394.321 247.615 390.884V341.054C247.615 337.613 244.827 334.824 241.389 334.824ZM197.789 347.28H226.351L197.789 375.847V347.28ZM235.159 384.65L206.597 384.655L235.159 356.088V384.65Z" fill="white"/>
          <path d="M37.9203 419.954H96.0541V478.088H37.9203V419.954Z" fill="white"/>
          <path d="M166.653 417.877H116.823C113.381 417.877 110.593 420.666 110.593 424.107V473.937C110.593 477.374 113.381 480.162 116.823 480.167H166.653C170.09 480.162 172.878 477.374 172.883 473.937V424.107C172.878 420.666 170.09 417.877 166.653 417.877ZM123.052 430.333H151.614L123.052 458.9V430.333ZM160.423 467.704L131.86 467.708L160.423 439.146V467.704Z" fill="white"/>
          <path d="M187.416 419.954H245.55V478.088H187.416V419.954Z" fill="white"/>
        </svg>


        <div className="flex justify-end flex-1 px-12 py-12 text-right">
            <div className="flex items-center space-x-4">
              <div className="font-bold ">
                  <p className="text-sm text-white/30">Open</p>
                  <p className="text-sm text-white">Scheduling</p>
              </div>

               <span className="p-2 text-white lg:p-2.5 rounded-xl bg-white/10">
                  <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.00019 15.7997C12.7557 15.7997 15.8002 12.7552 15.8002 8.99971C15.8002 5.24417 12.7557 2.19971 9.00019 2.19971C5.24466 2.19971 2.2002 5.24417 2.2002 8.99971C2.2002 12.7552 5.24466 15.7997 9.00019 15.7997Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M9 4.91992V8.99992L11.72 10.3599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
            </div>
        </div>
      </section>
    </div>
  );
}

export default MultiDoctors;
