import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import pattren from "../../images/pattren.svg";

function Appointments() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  return (
    <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Appointments" setSidebarOpen={setSidebarOpen}>
          <div className="items-center hidden space-x-4 lg:flex">
              <select className="block w-full py-3 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                  <option>Date Range</option>
                  <option>test</option>
                  <option>test</option>
                  <option>test</option>
                  <option>test</option>
              </select>

              <select className="block w-full py-3 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                  <option>UTC +5:30 Asia/Calcutta</option>
                  <option>test</option>
                  <option>test</option>
                  <option>test</option>
                  <option>test</option>
              </select>
          </div>
        </Header>

        <main className="flex flex-col flex-1 overflow-y-auto">
          <section className="px-4 py-8 lg:px-8 sm:px-6">
            <div className="md:flex md:items-center md:justify-between ">
              <div className="flex items-center space-x-4">
                <button className=" focus:outline-none">
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.8337 10H4.16699" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.0003 15.8333L4.16699 10L10.0003 4.16667" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>

                <button className=" focus:outline-none">
                  <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M5.05566 10H16.7223" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.8887 4.16667L16.722 10L10.8887 15.8333" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </button>

                <h3 className="text-xl font-medium text-gray-800">August, 2022</h3>
              </div>

              <div class="flex items-center justify-center mt-6 md:mt-0">
                  <div class="flex items-center border border-gray-300 rounded-md">
                      <button class="px-6 py-2.5 text-sm font-medium text-gray-800 capitalize">Day</button>
                      <button class="px-6 py-2.5 text-sm font-medium text-white bg-primary capitalize">Week</button>
                      <button class="px-6 py-2.5 text-sm font-medium text-gray-800 capitalize">Month</button>
                  </div>
              </div>
            </div>

            <div className="grid grid-cols-2 mt-12 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7 place-items-center">
              <button className=" focus:outline-none">
                <p className="text-sm text-gray-400 uppercase">Thu</p>
                <p className="mt-1 text-xl text-gray-800 ">31</p>
              </button>

              <button className=" focus:outline-none">
                <p className="text-sm text-gray-400 uppercase">FRI</p>
                <p className="mt-1 text-xl text-gray-800 ">1</p>
              </button>

              <button className="hidden focus:outline-none md:block">
                <p className="text-sm text-gray-400 uppercase">SAT</p>
                <p className="mt-1 text-xl text-gray-800 ">2</p>
              </button>

              <button className="hidden focus:outline-none md:block">
                <p className="text-sm text-gray-700 uppercase">SUN</p>
                <p className="flex items-center justify-center mt-1 text-xl text-white rounded-full w-7 h-7 bg-primary ">3</p>
              </button>

              <button className="hidden focus:outline-none lg:block">
                <p className="text-sm text-gray-400 uppercase">MON</p>
                <p className="mt-1 text-xl text-gray-800 ">4</p>
              </button>

              <button className="hidden focus:outline-none xl:block">
                <p className="text-sm text-gray-400 uppercase">TUE</p>
                <p className="mt-1 text-xl text-gray-800 ">5</p>
              </button>

              <button className="hidden focus:outline-none xl:block">
                <p className="text-sm text-gray-400 uppercase">Wed</p>
                <p className="mt-1 text-xl text-gray-800 ">6</p>
              </button>
            </div>
          </section>
          
          <section className="grid flex-1 grid-cols-2 bg-white border-t border-gray-300 divide-x md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-7">
            <div className="px-6 py-4 space-y-8 bg-white">
              <div>
                <p className="flex items-center space-x-3 ">
                  <p className=" h-3 w-3 rounded-full shrink-0 bg-[#E0716B]"></p>
                  <p className="text-sm text-gray-600 truncate ">Physiotherapy Sessiaaaaa aaaaaa</p>
                </p>

                <p className="mt-2 text-xs font-medium text-center text-gray-800">10:00 AM - 11:30 AM</p>
              </div>
            </div>

            <div className="px-6 py-4 space-y-8 bg-white">
              <div>
                <p className="flex items-center space-x-3 ">
                  <p className=" h-3 w-3 rounded-full shrink-0 bg-[#E0716B]"></p>
                  <p className="text-sm text-gray-600 truncate ">Physiotherapy Sessiaaaaa aaaaaa</p>
                </p>

                <p className="mt-2 text-xs font-medium text-center text-gray-800">10:00 AM - 11:30 AM</p>
              </div>
            </div>

            <div className="hidden px-6 py-4 space-y-8 bg-white md:block">
              <div>
                <p className="flex items-center space-x-3 ">
                  <p className=" h-3 w-3 rounded-full shrink-0 bg-[#E0716B]"></p>
                  <p className="text-sm text-gray-600 truncate ">Physiotherapy Sessiaaaaa aaaaaa</p>
                </p>

                <p className="mt-2 text-xs font-medium text-center text-gray-800">10:00 AM - 11:30 AM</p>
              </div>

              <div>
                <p className="flex items-center space-x-3 ">
                  <p className=" h-3 w-3 rounded-full shrink-0 bg-[#0066FF]"></p>
                  <p className="text-sm text-gray-600 truncate ">Physiotherapy Sessiaaaaa aaaaaa</p>
                </p>

                <p className="mt-2 text-xs font-medium text-center text-gray-800">10:00 AM - 11:30 AM</p>
              </div>
            </div>

            <div className="items-center justify-center hidden bg-white bg-cover border-t-4 md:flex border-primary border-l-gray-200" style={{ backgroundImage: `url(${pattren})` }} >
                <p className="text-sm text-gray-400 ">No Appointments</p>
            </div>

            <div className="hidden px-6 py-4 space-y-8 bg-white lg:block">
              <div>
                <p className="flex items-center space-x-3 ">
                  <p className=" h-3 w-3 rounded-full shrink-0 bg-[#E0716B]"></p>
                  <p className="text-sm text-gray-600 truncate ">Physiotherapy Sessiaaaaa aaaaaa</p>
                </p>

                <p className="mt-2 text-xs font-medium text-center text-gray-800">10:00 AM - 11:30 AM</p>
              </div>
            </div>

            <div className="hidden px-6 py-4 space-y-8 bg-white xl:block">
              <div>
                <p className="flex items-center space-x-3 ">
                  <p className=" h-3 w-3 rounded-full shrink-0 bg-[#E0716B]"></p>
                  <p className="text-sm text-gray-600 truncate ">Physiotherapy Sessiaaaaa aaaaaa</p>
                </p>

                <p className="mt-2 text-xs font-medium text-center text-gray-800">10:00 AM - 11:30 AM</p>
              </div>
            </div>

            <div className="items-center justify-center hidden bg-white bg-cover xl:flex" style={{ backgroundImage: `url(${pattren})` }} >
                <p className="text-sm text-gray-400 ">No Appointments</p>
            </div>
          </section>
        </main>
      </div>
    </div>
  );
}

export default Appointments;
