import React from "react";
import { Link } from "react-router-dom";

function Card(props) {
  return (
    <div className="p-6 bg-white border rounded-lg">
        <div className="flex items-center justify-between">
            <h2 className="text-xl font-medium text-gray-800">Online Consultation</h2>
            <button onClick={() => props.setEditAvailabilityOpen(true)} className="flex items-center justify-center w-8 h-8 bg-gray-100 rounded-full hover:bg-gray-200 ">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M2.9165 7H11.0832" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M7 2.91675L11.0833 7.00008L7 11.0834" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>
        </div>
        
        <p className="flex items-center mt-3 space-x-2 text-gray-500">
            <span>Mo, Tu, We</span>

            <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M6.81738 6.74994C6.99371 6.24869 7.34175 5.82602 7.79985 5.55679C8.25795 5.28756 8.79655 5.18914 9.32027 5.27897C9.84398 5.3688 10.319 5.64108 10.6612 6.04758C11.0034 6.45409 11.1907 6.96858 11.1899 7.49994C11.1899 8.99994 8.93988 9.74994 8.93988 9.74994" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M9 12.75H9.0075" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </p>

        <hr className="my-6" />

        <p className="text-sm font-medium text-gray-600">4 Events Using This Availability</p>

        <p className="flex items-center mt-4 space-x-2 text-gray-700">
            <span className="w-3 h-3 bg-orange-500 rounded-full"></span>
            <span>Physio Therapy Session</span>
        </p>

        <p className="flex items-center mt-2 space-x-2 text-gray-700">
            <span className="w-3 h-3 bg-yellow-500 rounded-full"></span>
            <span>Counselling Session</span>
        </p>

        <Link to="/events" className="flex items-center mt-4 space-x-2 text-gray-400 hover:underline">
            <span>View All Events</span>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M4.6665 11.3334L11.3332 4.66675" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                <path d="M4.6665 4.66675H11.3332V11.3334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
            </svg>
        </Link>
    </div>
  );
}

export default Card;
