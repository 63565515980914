import React from "react";

function createEvent(props) {
  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
        <div onClick={() => props.setCreateEventOpen(false)} className={"fixed inset-0 z-40 transition-opacity bg-black opacity-50 " + (props.createEventOpen ? 'block' : 'hidden')}></div>

        <div className={ "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[28rem] " + (props.createEventOpen ? 'translate-x-0 ease-in' : 'translate-x-full ease-out')}>
            <div className="flex items-center justify-between px-6 py-8 space-x-4 sm:space-x-0">
                <div>
                    <h3 className="text-2xl font-medium text-gray-800">Create Event</h3>
                </div>

                <button onClick={() => props.setCreateEventOpen(false)} className="text-gray-600 focus:text-primary focus:outline-none">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.5 4.5L13.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            
            <section>
                <div className="px-6 pb-6">
                    <label form="" className="text-sm font-medium text-gray-800">Create Event For? </label>

                    <div className="flex items-center mt-3 space-x-2">
                        <input name="type" id="formyself" type="radio" className="block p-2 border-gray-300 rounded-full focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                        
                        <label htmlFor="formyself" className="text-sm font-medium text-gray-600">For Myself </label>
                    </div>

                    <div className="flex items-center mt-3 space-x-2">
                        <input name="type" id="formyself2" type="radio" className="block p-2 border-gray-300 rounded-full focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                        
                        <label htmlFor="formyself2" className="text-sm font-medium text-gray-600">For Organization (For Other Doctors) </label>
                    </div>
                </div>
                
                <div className="flex items-center px-6 py-3 space-x-2 text-green-600 bg-green-100">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14.6663 7.38674V8.00007C14.6655 9.43769 14.2 10.8365 13.3392 11.988C12.4785 13.1394 11.2685 13.9817 9.88991 14.3893C8.51129 14.797 7.03785 14.748 5.68932 14.2498C4.3408 13.7516 3.18944 12.8308 2.40698 11.6248C1.62452 10.4188 1.25287 8.99212 1.34746 7.55762C1.44205 6.12312 1.99781 4.75762 2.93186 3.66479C3.86591 2.57195 5.1282 1.81033 6.53047 1.4935C7.93274 1.17668 9.39985 1.32163 10.713 1.90674" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M14.6667 2.66675L8 9.34008L6 7.34008" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                    <span>Basic Info</span>
                </div>
                
                {/* Step 1 content */}
                <main className="px-6 py-6">
                    <form>
                        <div>
                            <label className="text-sm font-medium text-gray-600">Name of the event </label>
                            
                            <input type="text" placeholder="Enter event name" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Event Color + Type</label>
                            
                            <div className="flex items-center space-x-4">
                                <select className="block py-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                    <option>color</option>
                                    <option>color</option>
                                    <option>color</option>
                                    <option>color</option>
                                </select>

                                <select className="block py-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                    <option>Select event type</option>
                                    <option>test</option>
                                    <option>test</option>
                                    <option>test</option>
                                </select>
                            </div>
                        </div>
                        
                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Event Description <span className="text-gray-400 ">(Optional)</span></label>
                            
                            <textarea placeholder="Describe the event" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm"></textarea>
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Event Template </label>
                            
                            <select className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                <option className="text-gray-400"></option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                            </select>
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Event location </label>
                            
                            <select className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                <option className="text-gray-400"></option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                            </select>
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Event URL <span className="text-gray-400 ">(Optional)</span> </label>
                            
                            <div className="flex mt-2 rounded-md shadow-sm">
                                <span className="inline-flex items-center px-3 text-gray-500 border border-r-0 border-gray-200 focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-500 rounded-l-md bg-gray-50 sm:text-sm" >
                                    osch.it/ 
                                </span>

                                <input type="text" placeholder="event-name" className="flex-1 block w-full min-w-0 px-3 py-3 border-gray-300 rounded-none rounded-r-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                            </div>
                        </div>

                        <button className="inline-flex justify-center w-full px-6 py-3 mt-8 mb-2 font-medium text-center text-white uppercase transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                            Next
                        </button>
                    </form>
                </main>
            </section>

            <section>
                <div className="px-6 py-3 bg-indigo-100 border-b text-primary">Step 2. Schedule</div>

                {/* Step 2 content */}
                <main className="px-6 py-8">
                    <form>
                        <div>
                            <label className="text-sm font-medium text-gray-600">Event Schedule</label>

                            <select className="block w-full py-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                <option>Select schedule</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                            </select>
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Duration of the event </label>
                            
                            <select className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                <option>Select duration</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                            </select>
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Calendar </label>
                            
                            <select className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                <option>Select duration</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                            </select>
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Range of date when you can accept the event <span className="text-gray-400 ">(Optional)</span> </label>
                            
                            <select className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                <option>Select range</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                            </select>
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Maximum number of events per day <span className="text-gray-400 ">(Optional)</span> </label>
                            
                            <select className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                <option>2</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                            </select>
                        </div>

                        <button className="inline-flex justify-center w-full px-6 py-3 mt-6 font-medium text-center text-white uppercase transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                            Next
                        </button>
                    </form>
                </main>
            </section>

            <section>
                <div className="px-6 py-3 text-gray-600 bg-gray-100">Step 3. Other Details</div>

                {/* Step 3 content */}
                <main className="px-6 py-8">
                    <form>
                        <div>
                            <label className="text-sm font-medium text-gray-600">- Add cancellation policy </label>
                            
                            <textarea className="block w-full h-24 p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">Please Cancel your appointment before 3 Hours of the appointment and you will recieve an message with confirmation.</textarea>
                        </div>

                        <div className="mt-8">
                            <label className="text-sm font-medium text-gray-600">Comments  <span className="text-gray-400 ">(Add Placeholder)</span> </label>
                            
                            <input type="text" placeholder="Tell us about the problems you’re experiencing." className="block w-full p-3 mt-2 placeholder-gray-300 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                        </div>
                        
                        <button className="inline-flex justify-center w-full px-6 py-3 mt-6 font-medium text-center text-white uppercase transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                            Next
                        </button>
                    </form>
                </main>
            </section>
        </div>
    </div>
  );
}

export default createEvent;
