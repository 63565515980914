import React from "react";

function createEvent(props) {
  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
        <div onClick={() => props.setCreateEventOpen(false)} className={"fixed inset-0 z-40 transition-opacity bg-black opacity-50 " + (props.createEventOpen ? 'block' : 'hidden')}></div>

        <div className={ "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[28rem] " + (props.createEventOpen ? 'translate-x-0 ease-in' : 'translate-x-full ease-out')}>
            <div className="flex items-center justify-between px-6 py-8 space-x-4 sm:space-x-0">
                <div>
                    <h3 className="text-2xl font-medium text-gray-800">Add New User </h3>
                    <p className="mt-2 text-sm font-medium text-gray-600">User will recieve an Email with an invite link</p>

                </div>

                <button onClick={() => props.setCreateEventOpen(false)} className="text-gray-600 focus:text-primary focus:outline-none">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.5 4.5L13.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>
            
            <section className="flex flex-col flex-1">
                <main className="flex flex-col flex-1 px-6 py-6">
                    <form className="flex flex-col flex-1">
                        <div className="flex-1">
                            <div>
                                <label className="text-sm font-medium text-gray-600">Name</label>
                                
                                <input type="text" placeholder="Enter Name Here" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                            </div>

                            <div className="mt-8">
                                <label className="text-sm font-medium text-gray-600">Role</label>
                                
                                <select className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                                    <option>Member</option>
                                    <option>test</option>
                                    <option>test</option>
                                    <option>test</option>
                                    <option>test</option>
                                </select>
                            </div>

                            <div className="mt-8">
                                <label className="text-sm font-medium text-gray-600">Email</label>
                                
                                <input type="email" placeholder="Enter Your Email" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                            </div>
                        </div>

                        <button className="inline-flex justify-center w-full px-6 py-3 mt-8 mb-2 font-medium text-center text-white uppercase transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                            Next
                        </button>
                    </form>
                </main>
            </section>
        </div>
    </div>
  );
}

export default createEvent;
