import React, { useState } from "react";
import Sidebar from "../../components/admins/Sidebar";
import Header from "../../components/Header";
import EventCreate from "../../components/admins/users/Create";
import { Link } from "react-router-dom";

function Users() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createEventOpen, setCreateEventOpen] = useState(false);
  
  return (
    <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <EventCreate createEventOpen={createEventOpen} setCreateEventOpen={setCreateEventOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Users" setSidebarOpen={setSidebarOpen}>
          <div className="items-center hidden space-x-10 lg:flex ">
            <Link to="/admins/users-fetch" className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.08333 5.8335L6.99999 8.75016L9.91666 5.8335" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 8.75V1.75" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <span>Fetch Users From Welkin</span>
            </Link>
            
            <button onClick={() => setCreateEventOpen(true)} className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <span>Create New User</span>
            </button>
          </div>
        </Header>

        <main className="flex-1 px-4 py-8 space-y-4 overflow-y-auto lg:px-8 sm:px-6">
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto xl:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="overflow-hidden rounded-xl">
                        <table className="min-w-full">
                            <thead>
                                <tr>
                                    <th scope="col" className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap">
                                        <button className="flex items-center space-x-3 text-xs font-normal text-left text-gray-400 uppercase hover:text-primary">
                                          <span>Name</span>

                                          <svg className="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.1"/>
                                              <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.1"/>
                                              <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.3"/>
                                            </svg>
                                        </button>
                                    </th>

                                    <th scope="col" className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap">
                                        <button className="flex items-center space-x-3 text-xs font-normal text-left text-gray-400 uppercase hover:text-primary">
                                          <span>Role</span>

                                          <svg className="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.1"/>
                                              <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.1"/>
                                              <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.3"/>
                                            </svg>
                                        </button>
                                    </th>

                                    <th scope="col" className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap">
                                        <button className="flex items-center space-x-3 text-xs font-normal text-left text-gray-400 uppercase hover:text-primary">
                                          <span>Email</span>

                                          <svg className="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.1"/>
                                              <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.1"/>
                                              <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.3"/>
                                            </svg>
                                        </button>
                                    </th>

                                    <th scope="col" className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap">
                                        <button className="flex items-center space-x-3 text-xs font-normal text-left text-gray-400 uppercase hover:text-primary">
                                          <span>Status</span>

                                          <svg className="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                              <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.1"/>
                                              <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.1"/>
                                              <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="#FEFEFE" stroke-width="0.3"/>
                                            </svg>
                                        </button>
                                    </th>

                                    <th scope="col" className="px-6 pt-4 pr-16 pb-7 whitespace-nowrap"></th>
                                </tr>
                            </thead>
                            
                            <tbody className="bg-white divide-y divide-gray-200 rounded-md">
                                <tr>
                                    <td className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap">
                                        Dr. Michelle Frank
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        Member
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        michellefrank@gmail.com
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                      <p className="flex items-center space-x-4 text-gray-600">
                                        <span className="w-3 h-3 bg-yellow-500 rounded-full"></span>
                                        <span>Deactivated Account</span>
                                      </p>
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        <div className="flex items-center lg:justify-end">
                                          <button className="flex items-center space-x-2 text-sm font-medium text-center text-gray-500 transition-colors duration-200 transform hover:text-red-500 focus:outline-none hover:bg-white">
                                              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                <path d="M2 4.5H3.33333H14" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.33334 4.50016V3.16683C5.33334 2.81321 5.47382 2.47407 5.72387 2.22402C5.97392 1.97397 6.31305 1.8335 6.66668 1.8335H9.33334C9.68697 1.8335 10.0261 1.97397 10.2762 2.22402C10.5262 2.47407 10.6667 2.81321 10.6667 3.16683V4.50016M12.6667 4.50016V13.8335C12.6667 14.1871 12.5262 14.5263 12.2762 14.7763C12.0261 15.0264 11.687 15.1668 11.3333 15.1668H4.66668C4.31305 15.1668 3.97392 15.0264 3.72387 14.7763C3.47382 14.5263 3.33334 14.1871 3.33334 13.8335V4.50016H12.6667Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6.66666 7.8335V11.8335" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.33334 7.8335V11.8335" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                              </svg>
                                              
                                              <span>Delete</span>
                                          </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap">
                                        Dr. Michelle Frank
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        Owner
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        michellefrank@gmail.com
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                      <p className="flex items-center space-x-4 text-gray-600">
                                        <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                                        <span>Active Account</span>
                                      </p>
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        <div className="flex items-center lg:justify-end">
                                          <button className="flex items-center space-x-2 text-sm font-medium text-center text-gray-500 transition-colors duration-200 transform hover:text-red-500 focus:outline-none hover:bg-white">
                                              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                <path d="M2 4.5H3.33333H14" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.33334 4.50016V3.16683C5.33334 2.81321 5.47382 2.47407 5.72387 2.22402C5.97392 1.97397 6.31305 1.8335 6.66668 1.8335H9.33334C9.68697 1.8335 10.0261 1.97397 10.2762 2.22402C10.5262 2.47407 10.6667 2.81321 10.6667 3.16683V4.50016M12.6667 4.50016V13.8335C12.6667 14.1871 12.5262 14.5263 12.2762 14.7763C12.0261 15.0264 11.687 15.1668 11.3333 15.1668H4.66668C4.31305 15.1668 3.97392 15.0264 3.72387 14.7763C3.47382 14.5263 3.33334 14.1871 3.33334 13.8335V4.50016H12.6667Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6.66666 7.8335V11.8335" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.33334 7.8335V11.8335" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                              </svg>
                                              
                                              <span>Delete</span>
                                          </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap">
                                        Dr. Michelle Frank
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        Owner
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        michellefrank@gmail.com
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                      <p className="flex items-center space-x-4 text-gray-600">
                                        <span className="w-3 h-3 bg-green-500 rounded-full"></span>
                                        <span>Active Account</span>
                                      </p>
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        <div className="flex items-center lg:justify-end">
                                          <button className="flex items-center space-x-2 text-sm font-medium text-center text-gray-500 transition-colors duration-200 transform hover:text-red-500 focus:outline-none hover:bg-white">
                                              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                <path d="M2 4.5H3.33333H14" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.33334 4.50016V3.16683C5.33334 2.81321 5.47382 2.47407 5.72387 2.22402C5.97392 1.97397 6.31305 1.8335 6.66668 1.8335H9.33334C9.68697 1.8335 10.0261 1.97397 10.2762 2.22402C10.5262 2.47407 10.6667 2.81321 10.6667 3.16683V4.50016M12.6667 4.50016V13.8335C12.6667 14.1871 12.5262 14.5263 12.2762 14.7763C12.0261 15.0264 11.687 15.1668 11.3333 15.1668H4.66668C4.31305 15.1668 3.97392 15.0264 3.72387 14.7763C3.47382 14.5263 3.33334 14.1871 3.33334 13.8335V4.50016H12.6667Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6.66666 7.8335V11.8335" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.33334 7.8335V11.8335" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                              </svg>
                                              
                                              <span>Delete</span>
                                          </button>
                                        </div>
                                    </td>
                                </tr>

                                <tr>
                                    <td className="px-6 py-4 font-medium text-gray-700 whitespace-nowrap">
                                        Dr. Michelle Frank
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        Member
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        michellefrank@gmail.com
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                      <p className="flex items-center space-x-4 text-gray-600">
                                        <span className="w-3 h-3 bg-yellow-500 rounded-full"></span>
                                        <span>Deactivated Account</span>
                                      </p>
                                    </td>

                                    <td className="px-6 py-8 text-sm text-gray-500 whitespace-nowrap">
                                        <div className="flex items-center lg:justify-end">
                                          <button className="flex items-center space-x-2 text-sm font-medium text-center text-gray-500 transition-colors duration-200 transform hover:text-red-500 focus:outline-none hover:bg-white">
                                              <svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g>
                                                <path d="M2 4.5H3.33333H14" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M5.33334 4.50016V3.16683C5.33334 2.81321 5.47382 2.47407 5.72387 2.22402C5.97392 1.97397 6.31305 1.8335 6.66668 1.8335H9.33334C9.68697 1.8335 10.0261 1.97397 10.2762 2.22402C10.5262 2.47407 10.6667 2.81321 10.6667 3.16683V4.50016M12.6667 4.50016V13.8335C12.6667 14.1871 12.5262 14.5263 12.2762 14.7763C12.0261 15.0264 11.687 15.1668 11.3333 15.1668H4.66668C4.31305 15.1668 3.97392 15.0264 3.72387 14.7763C3.47382 14.5263 3.33334 14.1871 3.33334 13.8335V4.50016H12.6667Z" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M6.66666 7.8335V11.8335" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M9.33334 7.8335V11.8335" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"/>
                                                </g>
                                              </svg>
                                              
                                              <span>Delete</span>
                                          </button>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
          </div>
        </main>
    </div>
    </div>
  );
}

export default Users;
