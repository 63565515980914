import React from "react";
import { Link } from "react-router-dom";

function Sidebar(props) {
  return (
    <div className="text-gray-800 bg-gray-100 lg:flex font-dm-sans">
        <div onClick={() => props.setSidebarOpen(false)} className={"fixed inset-0 z-20 transition-opacity bg-black opacity-50 lg:hidden " + (props.sidebarOpen ? 'block' : 'hidden')}></div>

        <div className={ "fixed inset-y-0 right-0 z-30 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-80 lg:w-64 2xl:w-72 lg:translate-x-0 lg:relative lg:inset-0 " + (props.sidebarOpen ? 'translate-x-0 ease-in' : 'translate-x-full ease-out')}>
            <div className="flex items-center justify-between px-6 py-12">
                <div className="flex items-center space-x-4">
                    <span className="p-2 text-white lg:p-2.5 rounded-xl bg-primary">
                        <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M9.00019 15.7997C12.7557 15.7997 15.8002 12.7552 15.8002 8.99971C15.8002 5.24417 12.7557 2.19971 9.00019 2.19971C5.24466 2.19971 2.2002 5.24417 2.2002 8.99971C2.2002 12.7552 5.24466 15.7997 9.00019 15.7997Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9 4.91992V8.99992L11.72 10.3599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        </svg>
                    </span>

                    <div className="font-bold ">
                        <p className="text-sm text-gray-500">Open</p>
                        <p className="text-sm text-gray-800">Scheduling</p>
                    </div>
                </div>

                <button onClick={() => props.setSidebarOpen(false)} className="text-gray-600 lg:hidden focus:text-primary focus:outline-none">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.5 4.5L13.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>

            <nav className="flex-1 px-6 space-y-3">
                <Link to="/admins/insights" className="flex items-center px-4 py-3 space-x-3 text-gray-600 transition-colors duration-200 transform bg-gray-200 rounded-lg bg-opacity-40">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.5 9H13.5L11.25 15.75L6.75 2.25L4.5 9H1.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

        
                    <span className="text-gray-800 capitalize">Insights</span>
                </Link>

                <Link to="/admins/events" className="flex items-center px-4 py-3 space-x-3 text-gray-500 transition-colors duration-200 transform rounded-lg hover:text-gray-600 hover:bg-gray-100 bg-opacity-40">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                        <path d="M14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25C15.0784 16.5 15.75 15.8284 15.75 15V4.5C15.75 3.67157 15.0784 3 14.25 3Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M12 1.5V4.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M6 1.5V4.5" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M2.25 7.5H15.75" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                    </svg>


                    <span className="capitalize">Events</span>
                </Link>

                <Link to="/appointments" className="flex items-center px-4 py-3 space-x-3 text-gray-500 transition-colors duration-200 transform rounded-lg hover:text-gray-600 hover:bg-gray-100 bg-opacity-40">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                        <path d="M16.5 8.31002V9.00002C16.4991 10.6173 15.9754 12.191 15.007 13.4864C14.0386 14.7818 12.6775 15.7294 11.1265 16.1879C9.57557 16.6465 7.91794 16.5914 6.40085 16.031C4.88376 15.4705 3.58849 14.4346 2.70822 13.0778C1.82795 11.721 1.40984 10.1161 1.51626 8.50226C1.62267 6.88844 2.24791 5.35227 3.29871 4.12283C4.34951 2.89339 5.76959 2.03656 7.34714 1.68013C8.92469 1.3237 10.5752 1.48677 12.0525 2.14502" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M16.5 3L9 10.5075L6.75 8.2575" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>

                    <span className="capitalize">Appointments</span>
                </Link>

                <Link to="/availability" className="flex items-center px-4 py-3 space-x-3 text-gray-500 transition-colors duration-200 transform rounded-lg hover:text-gray-600 hover:bg-gray-100 bg-opacity-40">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                        <path d="M9 16.5C13.1421 16.5 16.5 13.1421 16.5 9C16.5 4.85786 13.1421 1.5 9 1.5C4.85786 1.5 1.5 4.85786 1.5 9C1.5 13.1421 4.85786 16.5 9 16.5Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9 4.5V9L12 10.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>

                    <span className="capitalize">Availability</span>
                </Link>

                <Link to="/integrations" className="flex items-center px-4 py-3 space-x-3 text-gray-500 transition-colors duration-200 transform rounded-lg hover:text-gray-600 hover:bg-gray-100 bg-opacity-40">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                        <path d="M13.4987 2.00073C12.6253 2.00156 11.8159 2.45803 11.3631 3.20477C10.9106 3.95173 10.8805 4.88057 11.284 5.65518L5.6549 11.2843C4.94299 10.9129 4.09602 10.9061 3.3781 11.2659C2.66039 11.6259 2.15918 12.3088 2.03114 13.1015C1.90289 13.8943 2.16329 14.7002 2.73111 15.268C3.29893 15.8358 4.10484 16.0962 4.8976 15.968C5.69037 15.8399 6.37319 15.3387 6.73295 14.6208C7.09293 13.9031 7.08612 13.0561 6.7146 12.3442L12.3437 6.71515C13.0244 7.07142 13.8312 7.09492 14.5314 6.77865C15.2318 6.46237 15.7476 5.84158 15.9305 5.0952C16.1133 4.34903 15.9426 3.56001 15.4678 2.95593C14.993 2.35185 14.2668 1.9997 13.4985 2.00091L13.4987 2.00073ZM4.50011 14.4987C4.23497 14.4987 3.98055 14.3936 3.79311 14.206C3.60549 14.0186 3.50014 13.7641 3.50014 13.499C3.50014 13.2338 3.60549 12.9794 3.79311 12.792C3.98053 12.6043 4.23495 12.4992 4.50011 12.4992C4.76525 12.4992 5.01946 12.6043 5.20711 12.792C5.39452 12.9794 5.49988 13.2338 5.49988 13.499C5.49988 13.7641 5.39452 14.0185 5.20711 14.206C5.01949 14.3936 4.76528 14.4987 4.50011 14.4987ZM13.4987 5.50011C13.2336 5.50011 12.9791 5.39476 12.7917 5.20734C12.6041 5.01972 12.4989 4.76551 12.4989 4.50034C12.4989 4.2352 12.6041 3.98077 12.7917 3.79334C12.9791 3.60572 13.2335 3.50036 13.4987 3.50036C13.7638 3.50036 14.0183 3.60572 14.2057 3.79334C14.3933 3.98075 14.4985 4.23517 14.4985 4.50034C14.4985 4.76548 14.3933 5.01969 14.2057 5.20734C14.0183 5.39476 13.7639 5.50011 13.4987 5.50011Z" fill="black"/>
                        <path d="M13.7483 12.4988C13.7483 12.8301 13.6168 13.148 13.3824 13.3824C13.1479 13.6168 12.83 13.7484 12.4987 13.7484H8.74927V15.2481L12.4987 15.2483C13.2277 15.2483 13.9273 14.9584 14.4429 14.443C14.9583 13.9273 15.2482 13.2278 15.2482 12.4988V8.74927H13.7483L13.7483 12.4988Z" fill="black"/>
                        <path d="M4.25 5.50002C4.25 5.1687 4.38175 4.85076 4.61617 4.61637C4.85059 4.38195 5.16852 4.2502 5.49983 4.2502H9.24936V2.75049H5.49983C4.77061 2.75049 4.07125 3.04016 3.55558 3.55582C3.03991 4.07147 2.75024 4.77083 2.75024 5.50005V9.24953H4.24997L4.25 5.50002Z" fill="black"/>
                        </g>
                    </svg>


                    <span className="capitalize">Integrations</span>
                </Link>

                <Link to="/admins/event-templates" className="flex items-center px-4 py-3 space-x-3 text-gray-500 transition-colors duration-200 transform rounded-lg hover:text-gray-600 hover:bg-gray-100 bg-opacity-40">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                        <path d="M9.75 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V6.75L9.75 1.5Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M9.75 1.5V6.75H15" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>

                    <span className="capitalize">Event Templates</span>
                </Link>

                <Link to="/admins/users" className="flex items-center px-4 py-3 space-x-3 text-gray-500 transition-colors duration-200 transform rounded-lg hover:text-gray-600 hover:bg-gray-100 bg-opacity-40">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                        <path d="M12.75 15.75V14.25C12.75 13.4544 12.4339 12.6913 11.8713 12.1287C11.3087 11.5661 10.5456 11.25 9.75 11.25H3.75C2.95435 11.25 2.19129 11.5661 1.62868 12.1287C1.06607 12.6913 0.75 13.4544 0.75 14.25V15.75" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M6.75 8.25C8.40685 8.25 9.75 6.90685 9.75 5.25C9.75 3.59315 8.40685 2.25 6.75 2.25C5.09315 2.25 3.75 3.59315 3.75 5.25C3.75 6.90685 5.09315 8.25 6.75 8.25Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M17.25 15.7502V14.2502C17.2495 13.5855 17.0283 12.9397 16.621 12.4144C16.2138 11.889 15.6436 11.5138 15 11.3477" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M12 2.34766C12.6453 2.51288 13.2173 2.88818 13.6257 3.41439C14.0342 3.9406 14.2559 4.58778 14.2559 5.25391C14.2559 5.92003 14.0342 6.56722 13.6257 7.09342C13.2173 7.61963 12.6453 7.99493 12 8.16016" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>

                    <span className="capitalize">All Users</span>
                </Link>

                <Link to="/admins/team-calender" className="flex items-center px-4 py-3 space-x-3 text-gray-500 transition-colors duration-200 transform rounded-lg hover:text-gray-600 hover:bg-gray-100 bg-opacity-40">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g opacity="0.5">
                            <path d="M15.75 4.5C15.75 3.67157 15.0784 3 14.25 3H3.75C2.92157 3 2.25 3.67157 2.25 4.5V15C2.25 15.8284 2.92157 16.5 3.75 16.5H14.25" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M12 1.5V4.5" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M6 1.5V4.5" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M2.5 7H4.5" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.7475 15.7159V14.7474C13.7475 14.2337 13.5434 13.7411 13.1802 13.3778C12.817 13.0146 12.3243 12.8105 11.8106 12.8105H7.93687C7.42318 12.8105 6.93053 13.0146 6.5673 13.3778C6.20406 13.7411 6 14.2337 6 14.7474V15.7159" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M9.87364 10.8737C10.9433 10.8737 11.8105 10.0066 11.8105 8.93687C11.8105 7.86717 10.9433 7 9.87364 7C8.80393 7 7.93677 7.86717 7.93677 8.93687C7.93677 10.0066 8.80393 10.8737 9.87364 10.8737Z" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M16.6528 15.7164V14.7479C16.6525 14.3188 16.5097 13.9019 16.2468 13.5627C15.9838 13.2236 15.6157 12.9813 15.2002 12.874" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M13.2632 7.06299C13.6798 7.16966 14.0491 7.41196 14.3128 7.7517C14.5765 8.09143 14.7196 8.50926 14.7196 8.93933C14.7196 9.3694 14.5765 9.78724 14.3128 10.127C14.0491 10.4667 13.6798 10.709 13.2632 10.8157" stroke="black" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
                        </g>
                    </svg>

                    <span className="capitalize">Team Calendar</span>
                </Link>
            </nav>

            <hr className="my-6 border-gray-200"/>

            <div className="px-6 pt-4 pb-10">
                <Link to="/profile" className="flex items-center mt-4 space-x-3 text-lg font-bold text-gray-800 hover:text-gray-700 hover:underline">
                    <img className="object-cover w-16 h-16 rounded-full" src="https://images.unsplash.com/photo-1628890923662-2cb23c2e0cfe?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="" />
                </Link>

                <Link to="/profile" className="flex items-center mt-4 space-x-3 text-lg font-bold text-gray-800 hover:text-gray-700 hover:underline">
                    <span>Dr. Michelle Frank</span>

                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6" />
                    </svg>
                </Link>

                <p className="mt-1 text-sm text-gray-600">Sure Smile Dental Care</p>

                <button className="flex items-center mt-4 space-x-3 font-bold text-gray-400 hover:text-gray-500 hover:underline">
                    <span>Log Out</span>

                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                    </svg>
                </button>
            </div>
        </div>
    </div>
  );
}

export default Sidebar;
