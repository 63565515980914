import React from "react";
import slack from "../../images/image 45.png";

function createIntegrations(props) {
  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
        <div onClick={() => props.setCreateIntegrationsOpen(false)} className={"fixed inset-0 z-40 transition-opacity bg-black opacity-50 " + (props.createIntegrationsOpen ? 'block' : 'hidden')}></div>

        <div className={ "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[32rem] " + (props.createIntegrationsOpen ? 'translate-x-0 ease-in' : 'translate-x-full ease-out')}>
            <div className="flex items-center justify-between px-6 mt-12 space-x-4 sm:space-x-0">
                <div className="flex items-center space-x-4">
                    <img className="h-6" src={slack } alt="" />
                    <h3 className="text-xl font-bold text-gray-800 truncate">Slack </h3>
                </div>

                <button onClick={() => props.setCreateIntegrationsOpen(false)} className="text-gray-600 focus:text-primary focus:outline-none">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.5 4.5L13.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>

            <main className="px-6 py-6">
                <p className="text-gray-700">
                    When a <span className="font-bold text-gray-800">Counselling Event</span> is <span className="font-bold text-gray-800">Created</span> send a notification to <span className="font-bold text-gray-800">#new-appointment</span> 
                </p>

                <button className="flex items-center mt-4 space-x-2 text-sm text-primary focus:outline-none">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M11.3335 1.99998C11.5086 1.82488 11.7165 1.68599 11.9452 1.59123C12.174 1.49647 12.4192 1.44769 12.6668 1.44769C12.9145 1.44769 13.1597 1.49647 13.3884 1.59123C13.6172 1.68599 13.8251 1.82488 14.0002 1.99998C14.1753 2.17507 14.3142 2.38294 14.4089 2.61172C14.5037 2.84049 14.5524 3.08569 14.5524 3.33331C14.5524 3.58093 14.5037 3.82613 14.4089 4.05491C14.3142 4.28368 14.1753 4.49155 14.0002 4.66664L5.00016 13.6666L1.3335 14.6666L2.3335 11L11.3335 1.99998Z" stroke="#546CCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                    <span>Edit Event</span>
                </button>

                <div className="grid gap-4 mt-6 sm:grid-cols-3">
                    <div>
                        <label className="text-sm text-gray-500">Event</label>
                        <p className="mt-1 text-sm text-gray-800">Counselling Event</p>
                    </div>

                    <div>
                        <label className="text-sm text-gray-500">Trigger</label>
                        <p className="mt-1 text-sm text-gray-800">Event Created</p>
                    </div>

                    <div>
                        <label className="text-sm text-gray-500">Channel</label>
                        <p className="mt-1 text-sm text-gray-800">#new-appointments</p>
                    </div>
                </div>
                
                <hr className="my-8" />
                
                <form>
                    <h3 className="text-sm font-medium tracking-widest text-gray-500 uppercase">New event</h3>

                    <div className="mt-4">
                        <label className="text-sm text-gray-600">Select Event</label>
                        
                        <select className="block w-full py-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                            <option>Online Workshop</option>
                            <option>test</option>
                            <option>test</option>
                            <option>test</option>
                        </select>
                    </div>

                    <div className="mt-4">
                        <label className="text-sm text-gray-600">Trigger</label>
                        
                        <select className="block w-full py-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                            <option>Event Created</option>
                            <option>test</option>
                            <option>test</option>
                            <option>test</option>
                        </select>
                    </div>

                    <div className="mt-4">
                        <label className="text-sm text-gray-600">Channel</label>
                        
                        <select className="block w-full py-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                            <option>#new-workshops</option>
                            <option>test</option>
                            <option>test</option>
                            <option>test</option>
                        </select>
                    </div>
                    
                    <button className="px-6 py-2 mt-8 font-medium text-center text-white capitalize transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                        Save Event
                    </button>
                </form>

                <hr className="my-8" />

                <button className="flex items-center mt-4 space-x-2 text-sm text-primary focus:outline-none">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M8 3.33333V12.6667" stroke="#546CCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M3.3335 8H12.6668" stroke="#546CCC" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                    <span>Setup New Event</span>
                </button>
            </main>
        </div>
    </div>
  );
}

export default createIntegrations;
