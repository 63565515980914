import React, { useState } from "react";
import Sidebar from "../../components/admins/Sidebar";
import Header from "../../components/Header";
import CreateEvent from "../../components/admins/users/Create";
import { Link } from "react-router-dom";

function EmptyEvents() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createEventOpen, setCreateEventOpen] = useState(false);
  
  return (
    <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <CreateEvent createEventOpen={createEventOpen} setCreateEventOpen={setCreateEventOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Users" setSidebarOpen={setSidebarOpen}>
          <div className="items-center hidden space-x-10 lg:flex ">
            <Link to="/admins/users-fetch" className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.08333 5.8335L6.99999 8.75016L9.91666 5.8335" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 8.75V1.75" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <span>Fetch Users From Welkin</span>
            </Link>
            
            <button onClick={() => setCreateEventOpen(true)} className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <span>Create New User</span>
            </button>
          </div>
        </Header>

        <main className="flex flex-col items-center justify-center flex-1 space-y-4 overflow-y-auto">
            <div className="w-full max-w-2xl px-4 py-8 text-center sm:px-6">
                <h2 className="text-3xl font-medium text-gray-800 sm:text-4xl">No Users Added</h2>
                <p className="mt-4 text-center text-gray-500 ">Create new users and manage their </p>

                <div className="mt-6 sm:flex sm:items-center sm:justify-center sm:space-x-6">
                  <button onClick={() => setCreateEventOpen(true)} className="flex items-center justify-center w-full px-6 py-3 space-x-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md sm:w-auto bg-primary hover:bg-opacity-80">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                    </svg>

                    <span>Create New User</span>
                  </button>

                  <Link to="/admins/users-fetch" className="flex items-center justify-center w-full px-6 py-3 mt-6 space-x-3 font-medium text-center border rounded-md sm:mt-0 sm:w-auto border-primary focus:outline-none text-primary">
                    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M4.08333 5.8335L6.99999 8.75016L9.91666 5.8335" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                      <path d="M7 8.75V1.75" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>

                    <span>Fetch Users From Welkin</span>
                  </Link>
                </div>
            </div>
        </main>
    </div>
    </div>
  );
}

export default EmptyEvents;
