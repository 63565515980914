import React, { useState } from "react";
import Sidebar from "../../components/admins/Sidebar";
import Header from "../../components/Header";
import CreateEvent from "../../components/admins/users/Create";
import { Link } from "react-router-dom";

function FetchUsers() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [createEventOpen, setCreateEventOpen] = useState(false);
  
  return (
    <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <CreateEvent createEventOpen={createEventOpen} setCreateEventOpen={setCreateEventOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Users" setSidebarOpen={setSidebarOpen}>
          <div className="items-center hidden space-x-10 lg:flex ">
            <Link to="/admins/users-fetch" className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12.25 8.75V11.0833C12.25 11.3928 12.1271 11.6895 11.9083 11.9083C11.6895 12.1271 11.3928 12.25 11.0833 12.25H2.91667C2.60725 12.25 2.3105 12.1271 2.09171 11.9083C1.87292 11.6895 1.75 11.3928 1.75 11.0833V8.75" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M4.08333 5.8335L6.99999 8.75016L9.91666 5.8335" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M7 8.75V1.75" stroke="#546CCC" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              <span>Fetch Users From Welkin</span>
            </Link>
            
            <button onClick={() => setCreateEventOpen(true)} className="items-center hidden space-x-2 font-medium lg:flex focus:outline-none text-primary hover:text-gray-500">
              <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              <span>Create New User</span>
            </button>
          </div>
        </Header>

        <main className="flex flex-col items-center justify-center flex-1 space-y-4 overflow-y-auto">
            <button className="flex items-center justify-center w-full px-8 py-6 mt-6 space-x-1 text-lg text-center bg-white rounded-lg sm:mt-0 sm:w-auto focus:outline-none text-gray700">
                <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-primary" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>

                <span>Fetching Members From Welkin</span>
            </button>
        </main>
    </div>
    </div>
  );
}

export default FetchUsers;
