import React, { useState } from "react";
import Sidebar from "../../components/admins/Sidebar";
import Header from "../../components/Header";

function Users() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  return (
    <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Insights" setSidebarOpen={setSidebarOpen}></Header>

        <main className="flex-1 px-4 py-8 overflow-y-auto lg:px-8 sm:px-6">
          <h2 className="text-xl font-medium text-gray-800">Event Types</h2>

          <div className="flex items-center mt-4 space-x-6"> 
            <p className="flex items-center space-x-2 text-gray-600">
              <span className="w-3 h-3 bg-indigo-500 rounded-full"></span>
              <span>Office Hours</span>
            </p>

            <p className="flex items-center space-x-2 text-gray-600">
              <span className="w-3 h-3 bg-yellow-500 rounded-full"></span>
              <span>Online Consultation</span>
            </p>

            <p className="flex items-center space-x-2 text-gray-600">
              <span className="w-3 h-3 bg-orange-500 rounded-full"></span>
              <span>Physiotherapy Session</span>
            </p>
          </div>

          <div className="flex items-start mt-8">
            <div className="flex items-center p-4 space-x-8 bg-white rounded-xl">
              <div className="w-48 h-48 border-[12px] border-gray-200 rounded-full "></div>

              <div>
                <h2 className="text-xl font-semibold ">Today’s Insight</h2>
              </div>
            </div>
          </div>
        </main>
    </div>
    </div>
  );
}

export default Users;
