import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./views/doctors/Home";
import Welcome from "./views/doctors/Welcome";
import Profile from "./views/doctors/Profile";
import Events from "./views/doctors/Events";
import EmptyEvents from "./views/doctors/EmptyEvents";
import Appointments from "./views/doctors/Appointments";
import AppointmentsCalendar from "./views/doctors/AppointmentsCalendar";
import AppointmentsCalendar2 from "./views/doctors/AppointmentsCalendar2";
import Availability from "./views/doctors/Availability";
import Integrations from "./views/doctors/Integrations";
import Step1 from "./views/doctors/Step1";
import Step2 from "./views/doctors/Step2";
import Step3 from "./views/doctors/Step3";
import Step4 from "./views/doctors/Step4";
import Step5 from "./views/doctors/Step5";
import Step6 from "./views/doctors/Step6";
import Step7 from "./views/doctors/Step7";
import Login from "./views/doctors/Login";

import Booking from "./views/patients/Booking";
import MultiDoctors from "./views/patients/MultiDoctors";
import Doctor from "./views/patients/Doctor";

import AdminsEvents from "./views/admins/Events";
import AdminsEventTemplates from "./views/admins/EventTemplates";
import AdminsEmptyEventTemplates from "./views/admins/EmptyEventTemplates";
import AdminEmptyUsers from "./views/admins/EmptyUsers";
import AdminUsers from "./views/admins/Users";
import AdminFetchUsers from "./views/admins/FetchUsers";
import AdminInsights from "./views/admins/Insights";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route path="/" element={<Home />}></Route>
          <Route path="/welcome" element={<Welcome />}></Route>
          <Route path="/profile" element={<Profile />}></Route>
          <Route path="/events" element={<Events />}></Route>
          <Route path="/empty-events" element={<EmptyEvents />}></Route>
          <Route path="/appointments" element={<Appointments />}></Route>
          <Route path="/appointments-calendar" element={<AppointmentsCalendar />}></Route>
          <Route path="/appointments-calendar2" element={<AppointmentsCalendar2 />}></Route>
          <Route path="/availability" element={<Availability />}></Route>
          <Route path="/integrations" element={<Integrations />}></Route>
          <Route path="/step-1" element={<Step1 />}></Route>
          <Route path="/step-2" element={<Step2 />}></Route>
          <Route path="/step-3" element={<Step3 />}></Route>
          <Route path="/step-4" element={<Step4 />}></Route>
          <Route path="/step-5" element={<Step5 />}></Route>
          <Route path="/step-6" element={<Step6 />}></Route>
          <Route path="/step-7" element={<Step7 />}></Route>
          <Route path="/login" element={<Login />}></Route>

          <Route path="/booking" element={<Booking />}></Route>
          <Route path="/multi-doctors" element={<MultiDoctors />}></Route>
          <Route path="/doctor" element={<Doctor />}></Route>

          <Route path="/admins/events" element={<AdminsEvents />}></Route>
          <Route path="/admins/event-templates" element={<AdminsEventTemplates />}></Route>
          <Route path="/admins/empty-event-templates" element={<AdminsEmptyEventTemplates />}></Route>
          <Route path="/admins/empty-users" element={<AdminEmptyUsers />}></Route>
          <Route path="/admins/users" element={<AdminUsers />}></Route>
          <Route path="/admins/users-fetch" element={<AdminFetchUsers />}></Route>
          <Route path="/admins/insights" element={<AdminInsights />}></Route>
        </Routes>
      </Router>
    </div>
  );
}

export default App;
