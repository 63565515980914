import React from "react";

function Booking() {
  return (
    <div className="min-h-screen bg-primary flex flex-col lg:bg-[#F5F5F5] lg:flex-row font-dm-sans">
      <section className="flex flex-col flex-1 overflow-y-auto lg:w-2/3">
        <div className="py-8 lg:hidden">
          <div className="flex justify-center">
            <div className="flex items-center space-x-4">
                <span className="p-2 text-white lg:p-2.5 rounded-xl bg-white/10">
                  <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.00019 15.7997C12.7557 15.7997 15.8002 12.7552 15.8002 8.99971C15.8002 5.24417 12.7557 2.19971 9.00019 2.19971C5.24466 2.19971 2.2002 5.24417 2.2002 8.99971C2.2002 12.7552 5.24466 15.7997 9.00019 15.7997Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M9 4.91992V8.99992L11.72 10.3599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>

              <div className="font-bold ">
                  <p className="text-sm text-white/30">Open</p>
                  <p className="text-sm text-white">Scheduling</p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col flex-1 w-full max-w-3xl px-8 py-12 mx-auto bg-white lg:bg-transparent lg:min-h-screen rounded-t-2xl lg:justify-center xl:px-16">
          <a href="#" className="flex items-center space-x-1 text-sm font-bold text-gray-800 hover:text-gray-600">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M15 10.0002C15 10.1659 14.9342 10.3249 14.817 10.4421C14.6997 10.5593 14.5408 10.6252 14.375 10.6252H7.13377L9.81752 13.3077C9.87563 13.3658 9.92172 13.4348 9.95317 13.5107C9.98462 13.5866 10.0008 13.668 10.0008 13.7502C10.0008 13.8324 9.98462 13.9137 9.95317 13.9897C9.92172 14.0656 9.87563 14.1346 9.81752 14.1927C9.75941 14.2508 9.69042 14.2969 9.6145 14.3283C9.53857 14.3598 9.4572 14.376 9.37502 14.376C9.29284 14.376 9.21146 14.3598 9.13554 14.3283C9.05961 14.2969 8.99063 14.2508 8.93252 14.1927L5.18252 10.4427C5.12431 10.3846 5.07814 10.3157 5.04663 10.2397C5.01512 10.1638 4.9989 10.0824 4.9989 10.0002C4.9989 9.91797 5.01512 9.83657 5.04663 9.76064C5.07814 9.68471 5.12431 9.61574 5.18252 9.55768L8.93252 5.80768C9.04988 5.69032 9.20905 5.62439 9.37502 5.62439C9.54099 5.62439 9.70016 5.69032 9.81752 5.80768C9.93488 5.92504 10.0008 6.08421 10.0008 6.25018C10.0008 6.41615 9.93488 6.57532 9.81752 6.69268L7.13377 9.37518H14.375C14.5408 9.37518 14.6997 9.44103 14.817 9.55824C14.9342 9.67545 15 9.83442 15 10.0002Z" fill="currentColor"/>
            </svg>
            <span>Back</span>
          </a>

          <div className="bg-white lg:p-8 mt-7 rounded-xl ">
            <h2 className="text-2xl font-medium text-gray-800 ">Enter your personal details</h2>

            <form className="mt-6 space-y-6">
              <div>
                  <label className="text-sm font-medium text-gray-600">Your name </label>
                  
                  <input type="text" placeholder="Enter your name here" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
              </div>

              <div>
                  <label className="text-sm font-medium text-gray-600">Your Email ID </label>
                  
                  <input type="email" placeholder="Enter your email id" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
              </div>

              <div>
                  <label className="text-sm font-medium text-gray-600">Your mobile number </label>
                  
                  <input type="text" placeholder="Enter your mobile number" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
              </div>

              <div className="lg:flex lg:justify-center">
                <button className="w-full px-8 py-3 mt-6 font-medium text-center text-white transition-colors duration-200 transform rounded-md lg:w-auto focus:outline-none bg-primary hover:bg-opacity-80">
                    Confirm Booking
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="relative items-end hidden w-full lg:flex lg:w-1/3 bg-primary">
        <svg className="absolute right-0 -translate-y-1/2 top-1/2 opacity-20" width="426" height="516" viewBox="0 0 426 516" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M224.779 191.557L282.913 141.728L224.779 91.8981V116.813H133.426V166.643H224.779V191.557Z" fill="white"/>
          <path d="M374.275 17.157C418.778 17.1657 459.897 40.9192 482.142 79.4648C504.386 118.01 504.382 165.494 482.124 204.033C459.866 242.57 418.738 266.31 374.235 266.305C329.727 266.301 288.608 242.552 266.359 204.006L251.987 212.328C272.925 248.597 308.889 273.697 350.153 280.843C391.421 287.989 433.728 276.45 465.653 249.339C497.57 222.228 515.808 182.345 515.434 140.468C515.061 98.5908 496.113 59.0406 463.714 32.504C431.316 5.97072 388.812 -4.81386 347.676 3.06834C306.544 10.951 271.036 36.6858 250.747 73.3246L265.263 81.3871V81.3827C276.057 61.9303 291.853 45.7181 311.021 34.424C330.189 23.1298 352.03 17.168 374.275 17.1545L374.275 17.157Z" fill="white"/>
          <path d="M432.4 233.084V66.9889C432.4 64.7881 431.528 62.6748 429.967 61.1141C428.411 59.5578 426.298 58.6853 424.097 58.6853H324.436C319.851 58.6853 316.133 62.403 316.133 66.9888V233.084H357.659L357.655 191.562H390.874V233.088L432.4 233.084ZM349.352 166.647H332.74V150.035H349.352V166.647ZM349.352 133.428H332.74V116.816H349.352V133.428ZM349.352 100.209L332.74 100.205V83.5974H349.352V100.209ZM382.571 166.65L365.963 166.646V150.034H382.575L382.571 166.65ZM382.571 133.431L365.963 133.427V116.815H382.575L382.571 133.431ZM382.571 100.212L365.963 100.204V83.5964H382.575L382.571 100.212ZM399.182 83.6006H415.794V100.212L399.182 100.204V83.6006ZM399.182 116.82H415.794V133.431L399.182 133.427V116.82ZM399.182 150.039H415.794V166.65L399.182 166.646V150.039Z" fill="white"/>
          <path d="M8.85165 515.454H274.611C276.811 515.458 278.925 514.581 280.485 513.025C282.042 511.464 282.914 509.355 282.914 507.15V274.612C282.91 268.005 280.279 261.675 275.61 257.002C270.941 252.333 264.606 249.706 258 249.698H233.085V241.394C233.089 239.189 232.212 237.076 230.656 235.519C229.095 233.963 226.986 233.086 224.781 233.086H191.562C189.357 233.086 187.244 233.963 185.687 235.519C184.131 237.076 183.254 239.189 183.254 241.394V249.698H100.205V241.394C100.205 239.189 99.333 237.076 97.7766 235.519C96.2159 233.963 94.1027 233.086 91.9018 233.086H58.6828C56.4776 233.086 54.3644 233.963 52.808 235.519C51.2517 237.076 50.3748 239.189 50.3748 241.394V249.698H25.4599C18.8574 249.706 12.5227 252.333 7.85266 257.002C3.17912 261.675 0.553066 268.006 0.544312 274.612V507.15C0.544312 509.355 1.42114 511.464 2.97755 513.025C4.53389 514.581 6.64704 515.458 8.85233 515.454H8.85165ZM199.863 249.695H216.475V274.61H199.863V249.695ZM66.9889 249.695H83.6006V274.61H66.9889V249.695ZM17.1592 307.829H266.308V498.851H17.1592V307.829Z" fill="white"/>
          <path d="M37.9203 336.901H96.0541V395.034H37.9203V336.901Z" fill="white"/>
          <path d="M166.653 334.824H116.823C113.381 334.824 110.593 337.613 110.593 341.054V390.884C110.593 394.321 113.381 397.109 116.823 397.109H166.653C170.09 397.109 172.878 394.321 172.883 390.884V341.054C172.878 337.613 170.09 334.824 166.653 334.824ZM123.052 347.28H151.614L123.052 375.847V347.28ZM160.423 384.65L131.86 384.655L160.423 356.088V384.65Z" fill="white"/>
          <path d="M241.389 334.824H191.56C188.118 334.824 185.33 337.613 185.33 341.054V390.884C185.33 394.321 188.118 397.109 191.56 397.109H241.389C244.827 397.109 247.615 394.321 247.615 390.884V341.054C247.615 337.613 244.827 334.824 241.389 334.824ZM197.789 347.28H226.351L197.789 375.847V347.28ZM235.159 384.65L206.597 384.655L235.159 356.088V384.65Z" fill="white"/>
          <path d="M37.9203 419.954H96.0541V478.088H37.9203V419.954Z" fill="white"/>
          <path d="M166.653 417.877H116.823C113.381 417.877 110.593 420.666 110.593 424.107V473.937C110.593 477.374 113.381 480.162 116.823 480.167H166.653C170.09 480.162 172.878 477.374 172.883 473.937V424.107C172.878 420.666 170.09 417.877 166.653 417.877ZM123.052 430.333H151.614L123.052 458.9V430.333ZM160.423 467.704L131.86 467.708L160.423 439.146V467.704Z" fill="white"/>
          <path d="M187.416 419.954H245.55V478.088H187.416V419.954Z" fill="white"/>
        </svg>


        <div className="flex justify-end flex-1 px-12 py-12 text-right">
            <div className="flex items-center space-x-4">
              <div className="font-bold ">
                  <p className="text-sm text-white/30">Open</p>
                  <p className="text-sm text-white">Scheduling</p>
              </div>

               <span className="p-2 text-white lg:p-2.5 rounded-xl bg-white/10">
                  <svg width="22" height="22" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M9.00019 15.7997C12.7557 15.7997 15.8002 12.7552 15.8002 8.99971C15.8002 5.24417 12.7557 2.19971 9.00019 2.19971C5.24466 2.19971 2.2002 5.24417 2.2002 8.99971C2.2002 12.7552 5.24466 15.7997 9.00019 15.7997Z" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M9 4.91992V8.99992L11.72 10.3599" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
              </span>
            </div>
        </div>
      </section>
    </div>
  );
}

export default Booking;
