import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";
import AppointmentsCard from "../../components/appointments/Card";

function Appointments() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  return (
    <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Appointments" setSidebarOpen={setSidebarOpen}>
          <div className="items-center hidden space-x-4 lg:flex">
              <select className="block w-full py-3 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                  <option>Date Range</option>
                  <option>test</option>
                  <option>test</option>
                  <option>test</option>
                  <option>test</option>
              </select>

              <select className="block w-full py-3 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                  <option>UTC +5:30 Asia/Calcutta</option>
                  <option>test</option>
                  <option>test</option>
                  <option>test</option>
                  <option>test</option>
              </select>
          </div>
        </Header>

        <main className="flex-1 px-4 py-8 overflow-y-auto lg:px-8 sm:px-6">
          <div>
            <h4 className="text-gray-600"><span className="font-medium ">Today</span>, 13 August 2021</h4>

            <div className="mt-6 space-y-6">
              <AppointmentsCard />
              <AppointmentsCard />
            </div>
          </div>

          <div className="mt-[4.5rem]">
            <h4 className="text-gray-600"><span className="font-medium ">Today</span>, 13 August 2021</h4>

            <div className="mt-6 space-y-6">
              <AppointmentsCard />
              <AppointmentsCard />
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Appointments;
