import React from "react";
import { Link } from "react-router-dom";
import Steps from "../../components/Steps";


function Step3() {
  return (
    <div className="h-screen lg:flex font-dm-sans">
      <section className="overflow-y-auto lg:w-2/5">
        <div className="flex flex-col w-full max-w-2xl min-h-screen px-8 py-12 mx-auto xl:px-16 md:px-10">
          <Steps></Steps>

          <div className="flex flex-col flex-1 mt-[3.5rem]">
            <div className="flex-1">
              <h3 className="text-2xl font-bold text-gray-800">
                Organization
              </h3>

              <p className="mt-2 text-base text-gray-500">Do you want to create this account new organization or join one?</p>

              <div className="mt-12 space-y-4">
                <Link to="/step-4" className="block w-full px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
                  Create New Organization
                </Link>

                <Link to="/step-5" className="block w-full px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
                  Join Existing Organization
                </Link>
              </div>
            </div>
            
            <div className="flex items-center justify-center mt-12">
              <button className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
                Back
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="relative items-end hidden w-full lg:flex lg:w-3/5 bg-primary">
        <svg className="absolute bottom-0 right-0" width="525" height="488" viewBox="0 0 525 488" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.1">
            <path d="M2.82046 222.569C-8.92588 178.731 17.0895 133.671 60.9274 121.925L505.431 2.82046C549.269 -8.92588 594.329 17.0895 606.075 60.9274L725.18 505.431C736.926 549.269 710.911 594.329 667.073 606.075L222.569 725.18C178.731 736.926 133.671 710.911 121.925 667.073L2.82046 222.569Z" fill="black"/>
            <path d="M286.801 320.465C332.309 308.271 362.189 328.765 376.439 381.946L402.599 479.578C404.584 486.987 403.895 493.41 400.531 498.849C397.432 504.216 392.046 507.928 384.373 509.984C376.7 512.04 370.048 511.554 364.415 508.526C358.783 505.498 354.974 500.28 352.989 492.871L326.829 395.239C323.071 381.216 317.431 371.81 309.909 367.019C302.58 361.893 292.831 360.961 280.66 364.222C266.372 368.051 256.068 375.632 249.747 386.968C243.691 398.232 242.683 411.405 246.724 426.487L270.439 514.991C272.424 522.399 271.734 528.823 268.371 534.261C265.007 539.7 259.489 543.447 251.816 545.503C244.143 547.559 237.49 547.073 231.858 544.045C226.49 540.946 222.814 535.692 220.829 528.284L157.236 290.95C155.392 284.071 156.152 277.912 159.516 272.474C163.144 266.964 168.663 263.217 176.071 261.232C183.479 259.247 189.964 259.636 195.526 262.4C201.087 265.163 204.789 269.985 206.633 276.864L231.411 369.336C235.26 357.245 241.9 347.1 251.33 338.902C260.954 330.368 272.778 324.222 286.801 320.465Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M473.54 182.747C484.85 183.644 493.292 193.541 492.395 204.851L471.014 474.311C470.117 485.622 460.22 494.063 448.91 493.166C437.599 492.268 429.158 482.372 430.055 471.061L451.435 201.601C452.333 190.291 462.229 181.849 473.54 182.747Z" fill="white"/>
            </g>
        </svg>

        <div className="max-w-lg px-12 py-12">
            <div className="flex items-center space-x-3">
                <button className="w-6 h-2.5 bg-white rounded-full"></button>
                <button className="w-2.5 h-2.5 bg-white bg-opacity-40 rounded-full"></button>
                <button className="w-2.5 h-2.5 bg-white bg-opacity-40 rounded-full"></button>
            </div>

            <h2 className="mt-12 text-4xl font-bold text-white">Be Efficient with Effective Scheduling</h2>

            <p className="mt-6 text-lg text-white/80">Integrate with your existing calendars to serve your customers</p>
        </div>
      </section>
    </div>
  );
}

export default Step3;
