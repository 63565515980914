import React, { useState } from "react";
import Sidebar from "../../components/Sidebar";
import Header from "../../components/Header";

function Profile() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  
  return (
    <div className="h-screen bg-gray-100 lg:overflow-hidden lg:flex font-dm-sans">
      <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

      <div className="flex flex-col flex-1 min-h-screen overflow-hidden bg-gray-100">
        <Header title="Profile" setSidebarOpen={setSidebarOpen} />

        <main className="flex-1 px-4 py-8 overflow-y-auto">
          <form className="max-w-2xl">
            <div className="sm:flex sm:items-center sm:justify-between">
              <h2 className="text-xl font-medium text-gray-800">Default Profile</h2>
              <div className="flex items-center justify-center mt-4 space-x-4 sm:mt-0">
                <button className="px-6 py-2 font-medium text-center text-white capitalize transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                  Save Changes
                </button>

                <button className="px-8 py-2 font-medium text-center transition-colors duration-200 transform border rounded-md text-primary border-primary hover:bg-white">
                  Reset
                </button>
              </div>
            </div>
            
            <div className="p-8 mt-6 bg-white shadow rounded-xl"> 
              <div className="space-y-6 sm:flex sm:space-y-0 sm:items-center sm:space-x-4">
                <div className="sm:w-1/2">
                    <label className="text-sm font-medium text-gray-400">First Name </label>
                    
                    <input type="text" value="Michelle" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                </div>

                <div className="sm:w-1/2">
                    <label className="text-sm font-medium text-gray-400">Last Name </label>
                    
                    <input type="text" value="Frank" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                </div>
              </div>

              <div className="mt-6">
                <label className="text-sm font-medium text-gray-400">Email </label>
                
                <input type="email" value="michellefrank@gmail.com" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
              </div>

              <div className="mt-6">
                <label className="text-sm font-medium text-gray-400">Organization Name </label>
                
                <input type="text" value="Sure Smile Dental Care Cli|" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
              </div>

              <div className="mt-6">
                  <label className="text-sm font-medium text-gray-400">Profile Picture</label>
                  
                  <div className="mt-2">
                    <label htmlFor="file-upload" className="flex justify-center max-w-2xl px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer">
                      <div className="space-y-1 text-center">
                        <svg
                          className="w-12 h-12 mx-auto text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm font-medium text-gray-400">
                          <span>Upload a file</span>
                          <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </label>

                    <div className="flex items-center justify-end mt-4 space-x-4">
                      <button type="button" className="flex items-center space-x-2 text-gray-400 hover:text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        <span>View</span>
                      </button>

                      <button type="button" className="flex items-center space-x-2 text-gray-400 hover:text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                        <span>Remove</span>
                      </button>
                    </div>
                  </div>
                </div>

              <div className="mt-6">
                  <label className="text-sm font-medium text-gray-400">Timezone </label>
                  
                  <select className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                      <option>UTC +5:30 Asia/Calcutta</option>
                      <option>test</option>
                      <option>test</option>
                      <option>test</option>
                      <option>test</option>
                  </select>
              </div>

              <div className="mt-6">
                <label className="text-sm font-medium text-gray-400">Calendar Domain </label>
                
                <input type="text" value="https://openscheduling.dev/schedule/for/michellefrank" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
              </div>

              <hr className="my-8" />

              <div>
                <label className="text-sm font-medium text-gray-400">Organization Name </label>
                
                <input type="text" value="Sure Smile Dental Care Cli|" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
              </div>

              {/* <hr className="my-8" />

              <div className="flex items-center justify-between">
                  <div>
                    <h3 className="text-xl font-medium text-gray-800">Delete Account</h3>
                    <p className="mt-2 text-sm text-gray-500">Once you delete your account, there is no going back.</p>
                  </div>
                  
                  <button className="flex items-center px-4 py-2 space-x-3 font-medium text-center text-red-500 transition-colors duration-200 transform border rounded-md hover:bg-white">
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M2 4H3.33333H14" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M5.33325 4.00004V2.66671C5.33325 2.31309 5.47373 1.97395 5.72378 1.7239C5.97383 1.47385 6.31296 1.33337 6.66659 1.33337H9.33325C9.68687 1.33337 10.026 1.47385 10.2761 1.7239C10.5261 1.97395 10.6666 2.31309 10.6666 2.66671V4.00004M12.6666 4.00004V13.3334C12.6666 13.687 12.5261 14.0261 12.2761 14.2762C12.026 14.5262 11.6869 14.6667 11.3333 14.6667H4.66659C4.31296 14.6667 3.97382 14.5262 3.72378 14.2762C3.47373 14.0261 3.33325 13.687 3.33325 13.3334V4.00004H12.6666Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M6.66675 7.33337V11.3334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                      <path d="M9.33325 7.33337V11.3334" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>

                    <span>Delete Account</span>
                  </button>
              </div>   */}
          </div>
          </form>
        </main>
    </div>
    </div>
  );
}

export default Profile;
