import React from "react";

function Login() {
  return (
    <main className="bg-[#F5F5F5] h-screen font-dm-sans flex justify-center items-center">
      <div className="flex flex-col items-center max-w-sm mx-auto">
        <svg width="60" height="59" viewBox="0 0 60 59" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M20.57 18.93C28.5967 18.93 32.61 23.62 32.61 33V50.22C32.61 51.5267 32.2133 52.5533 31.42 53.3C30.6733 54.0467 29.6233 54.42 28.27 54.42C26.9167 54.42 25.8433 54.0467 25.05 53.3C24.2567 52.5533 23.86 51.5267 23.86 50.22V33C23.86 30.5267 23.3467 28.73 22.32 27.61C21.34 26.4433 19.7767 25.86 17.63 25.86C15.11 25.86 13.08 26.6533 11.54 28.24C10.0467 29.8267 9.3 31.95 9.3 34.61V50.22C9.3 51.5267 8.90333 52.5533 8.11 53.3C7.31667 54.0467 6.24333 54.42 4.89 54.42C3.53667 54.42 2.46333 54.0467 1.67 53.3C0.923334 52.5533 0.55 51.5267 0.55 50.22V8.36C0.55 7.14666 0.946667 6.16666 1.74 5.42C2.58 4.67333 3.65333 4.3 4.96 4.3C6.26667 4.3 7.31667 4.65 8.11 5.35C8.90333 6.05 9.3 7.00667 9.3 8.22V24.53C10.4667 22.71 12.0067 21.3333 13.92 20.4C15.88 19.42 18.0967 18.93 20.57 18.93ZM43.1073 56.38C42.8273 57.2667 42.3839 57.92 41.7773 58.34C41.1706 58.76 40.4939 58.97 39.7473 58.97C38.8139 58.97 37.9739 58.6433 37.2273 57.99C36.5273 57.3833 36.1773 56.59 36.1773 55.61C36.1773 55.19 36.2473 54.77 36.3873 54.35L52.6273 3.46C52.9073 2.57333 53.3506 1.92 53.9573 1.5C54.5639 1.08 55.2406 0.869996 55.9873 0.869996C56.9206 0.869996 57.7373 1.19666 58.4373 1.85C59.1839 2.45666 59.5573 3.25 59.5573 4.23C59.5573 4.65 59.4873 5.07 59.3473 5.49L43.1073 56.38Z" fill="#C7C7C7"/>
        </svg>

        <h2 className="mt-4 text-2xl font-bold text-gray-800">Welcome</h2>

        <p className="mt-2 text-base text-gray-700">Log in to hltl.dev to continue Patient App</p>

        <form className="w-full mt-6">
          <div class="w-full">
              <label class="block mb-2 text-xs font-bold text-gray-600 uppercase">Email</label>

              <input placeholder="name@email.com" class="block w-full placeholder-gray-400 px-3 py-2 border-gray-300 text-gray-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" type="text" />
          </div>

          <div class="w-full mt-6">
              <label class="block mb-2 text-xs font-bold text-gray-600 uppercase">password</label>

              <input placeholder="********" class="block w-full placeholder-gray-400 px-3 py-2 border-gray-300 text-gray-700 bg-white border rounded-md focus:border-gray-400 focus:ring-gray-300 focus:outline-none focus:ring focus:ring-opacity-40" type="password" />

              <a href="#" className="block mt-2 text-sm font-normal text-gray-700 hover:underline">Forgot password?</a>
          </div>

          <button className="bg-[#C7C7C7] mt-6 py-2.5 px-4 w-full rounded-md hover:bg-[#C7C7C7]/50 font-medium">
            Continue
          </button>

          <a href="#" className="block mt-4 text-sm font-normal text-gray-700 hover:underline">Don’t have an account? Sign up</a>

          <div class="flex items-center justify-between mt-4 space-x-6">
              <span class="flex-1 block border-b"></span>

              <span href="#" class="text-xs text-center text-gray-500 uppercase">OR</span>

              <span class="flex-1 block border-b"></span>
          </div>

          <button className="bg-[#C7C7C7] mt-6 py-2.5 px-4 w-full rounded-md hover:bg-[#C7C7C7]/50 font-medium">
            Continue with Google
          </button>

          <button className="bg-[#C7C7C7] mt-2 py-2.5 px-4 w-full rounded-md hover:bg-[#C7C7C7]/50 font-medium">
            Continue with Apple
          </button>
        </form>
      </div>
    </main>
  );
}

export default Login;
