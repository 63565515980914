import React from "react";
import Steps from "../../components/Steps";

function Step1() {
  return (
    <div className="overflow-hidden lg:h-screen lg:flex font-dm-sans">
      <section className="overflow-y-auto lg:w-2/5">
        <div className="flex flex-col w-full max-w-2xl min-h-screen px-8 py-12 mx-auto xl:px-16 md:px-10">
          <Steps></Steps>

          <div className="flex flex-col flex-1 mt-[3.5rem]">
            <div>
              <h3 className="text-2xl font-bold text-gray-800">
                About You
              </h3>

              <p className="mt-2 text-base text-gray-500">We need information about you to create your profile</p>
            </div>

            <form className="flex flex-col flex-1 mt-8 space-y-6">
              <div className="flex-1 space-y-6 ">
                <div>
                  <label className="font-medium text-gray-400">Profile Picture</label>
                  
                  <div className="mt-2">
                    <label htmlFor="file-upload" className="flex justify-center max-w-2xl px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md cursor-pointer">
                      <div className="space-y-1 text-center">
                        <svg
                          className="w-12 h-12 mx-auto text-gray-400"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 48 48"
                          aria-hidden="true"
                        >
                          <path
                            d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                            strokeWidth={2}
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          />
                        </svg>
                        <div className="flex text-sm text-gray-600">
                          <span>Upload a file</span>
                          <input id="file-upload" name="file-upload" type="file" className="sr-only" />
                          <p className="pl-1">or drag and drop</p>
                        </div>
                        <p className="text-xs text-gray-500">PNG, JPG, GIF up to 10MB</p>
                      </div>
                    </label>

                    <div className="flex items-center justify-end mt-4 space-x-4">
                      <button type="button" className="flex items-center space-x-2 text-gray-400 hover:text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" />
                        </svg>
                        <span>View</span>
                      </button>

                      <button type="button" className="flex items-center space-x-2 text-gray-400 hover:text-primary">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                        </svg>
                        <span>Remove</span>
                      </button>
                    </div>
                  </div>
                </div>
                
                <div className="flex items-center space-x-4">
                    <div className="w-1/2">
                      <label className="font-medium text-gray-400">First Name</label>
                      
                      <input type="text" placeholder="mihir@humanx.io" className="block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                    </div>

                    <div className="w-1/2">
                      <label className="font-medium text-gray-400">Last Name</label>
                      
                      <input type="text" placeholder="mihir@humanx.io" className="block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                    </div>
                </div>

                <div>
                  <label className="font-medium text-gray-400">Email</label>
                  
                  <input type="email" placeholder="mihir@humanx.io" className="block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                </div>

                <div>
                  <label className="font-medium text-gray-400">Select Timezone</label>
                  
                  <select className="block w-full p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                    <option>UTC-08:00 Anchorage</option>
                    <option>UTC-08:00 Anchorage</option>
                    <option>UTC-08:00 Anchorage</option>
                    <option>UTC-08:00 Anchorage</option>
                  </select>
                </div>
              </div>

              <div className="flex items-center space-x-4">
                <button className="w-1/2 px-8 py-3 space-x-3 font-medium text-center transition-colors duration-200 transform border rounded-md focus:outline-none text-primary border-primary hover:bg-white">
                  Back
                </button>

                <button className="w-1/2 px-8 py-3 font-medium text-center text-white transition-colors duration-200 transform rounded-md focus:outline-none bg-primary hover:bg-opacity-80">
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>

      <section className="relative items-end hidden w-full lg:flex lg:w-3/5 bg-primary">
        <svg className="absolute bottom-0 right-0 z-10" width="525" height="488" viewBox="0 0 525 488" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.1">
            <path d="M2.82046 222.569C-8.92588 178.731 17.0895 133.671 60.9274 121.925L505.431 2.82046C549.269 -8.92588 594.329 17.0895 606.075 60.9274L725.18 505.431C736.926 549.269 710.911 594.329 667.073 606.075L222.569 725.18C178.731 736.926 133.671 710.911 121.925 667.073L2.82046 222.569Z" fill="black"/>
            <path d="M286.801 320.465C332.309 308.271 362.189 328.765 376.439 381.946L402.599 479.578C404.584 486.987 403.895 493.41 400.531 498.849C397.432 504.216 392.046 507.928 384.373 509.984C376.7 512.04 370.048 511.554 364.415 508.526C358.783 505.498 354.974 500.28 352.989 492.871L326.829 395.239C323.071 381.216 317.431 371.81 309.909 367.019C302.58 361.893 292.831 360.961 280.66 364.222C266.372 368.051 256.068 375.632 249.747 386.968C243.691 398.232 242.683 411.405 246.724 426.487L270.439 514.991C272.424 522.399 271.734 528.823 268.371 534.261C265.007 539.7 259.489 543.447 251.816 545.503C244.143 547.559 237.49 547.073 231.858 544.045C226.49 540.946 222.814 535.692 220.829 528.284L157.236 290.95C155.392 284.071 156.152 277.912 159.516 272.474C163.144 266.964 168.663 263.217 176.071 261.232C183.479 259.247 189.964 259.636 195.526 262.4C201.087 265.163 204.789 269.985 206.633 276.864L231.411 369.336C235.26 357.245 241.9 347.1 251.33 338.902C260.954 330.368 272.778 324.222 286.801 320.465Z" fill="white"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M473.54 182.747C484.85 183.644 493.292 193.541 492.395 204.851L471.014 474.311C470.117 485.622 460.22 494.063 448.91 493.166C437.599 492.268 429.158 482.372 430.055 471.061L451.435 201.601C452.333 190.291 462.229 181.849 473.54 182.747Z" fill="white"/>
            </g>
        </svg>

        <div className="absolute right-0 z-20 items-center hidden px-8 bg-white xl:flex h-28 rounded-l-xl w-80 2xl:w-96 lg:bottom-44">
          <div className="flex items-center space-x-4">
            <img className="object-cover w-16 h-16 rounded-full" src="https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=880&q=80" alt="" />
            <div>
              <h3 className="text-xl font-medium text-gray-500">Your Name</h3>
              <p className="font-light text-gray-500">Organization Name</p>
            </div>
          </div>
        </div>

        <div className="absolute bottom-0 right-0 z-20 hidden px-8 pt-6 bg-white rounded-tl-xl w-80 xl:block 2xl:w-96">
          <select className="block p-3 mt-2 placeholder-gray-400 border-gray-300 rounded-md w-52 focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
            <option>AUGUST 2021</option>
            <option>AUGUST 2021</option>
            <option>AUGUST 2021</option>
            <option>AUGUST 2021</option>
          </select>

          <div className="flex items-center mt-8 space-x-8">
            <button>
              <svg width="6" height="13" viewBox="0 0 6 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M5.2 11.314L1 6.157L5.2 1" stroke="#B7B9C1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
              </svg>
            </button>

            <div>
              <svg width="35" height="54" viewBox="0 0 35 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" width="27" height="9" rx="4" fill="#E7E7E7"/>
                <rect x="4" y="50" width="27" height="9" rx="4" fill="#E7E7E7"/>
                <rect y="16" width="35" height="27" rx="4" fill="#E7E7E7"/>
              </svg>
            </div>

            <div>
              <svg width="35" height="54" viewBox="0 0 35 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" width="27" height="9" rx="4" fill="#E7E7E7"/>
                <rect x="4" y="50" width="27" height="9" rx="4" fill="#E7E7E7"/>
                <rect y="16" width="35" height="27" rx="4" fill="#E7E7E7"/>
              </svg>
            </div>

            <div>
              <svg width="35" height="54" viewBox="0 0 35 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" width="27" height="9" rx="4" fill="#E7E7E7"/>
                <rect x="4" y="50" width="27" height="9" rx="4" fill="#E7E7E7"/>
                <rect y="16" width="35" height="27" rx="4" fill="#E7E7E7"/>
              </svg>
            </div>

            <div>
              <svg width="35" height="54" viewBox="0 0 35 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="4" width="27" height="9" rx="4" fill="#E7E7E7"/>
                <rect x="4" y="50" width="27" height="9" rx="4" fill="#E7E7E7"/>
                <rect y="16" width="35" height="27" rx="4" fill="#E7E7E7"/>
              </svg>
            </div>
          </div>
        </div>

        <div className="max-w-lg px-12 py-12">
            <div className="flex items-center space-x-3">
                <button className="w-6 h-2.5 bg-white rounded-full"></button>
                <button className="w-2.5 h-2.5 bg-white bg-opacity-40 rounded-full"></button>
                <button className="w-2.5 h-2.5 bg-white bg-opacity-40 rounded-full"></button>
            </div>

            <h2 className="mt-12 text-4xl font-bold text-white">Be Efficient with Effective Scheduling</h2>

            <p className="mt-6 text-lg text-white/80">Integrate with your existing calendars to serve your customers</p>
        </div>
      </section>
    </div>
  );
}

export default Step1;
