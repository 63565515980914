import React from "react";

function editAvailabilityOpen(props) {
  return (
    <div className="text-gray-800 bg-gray-100 font-dm-sans">
        <div onClick={() => props.setEditAvailabilityOpen(false)} className={"fixed inset-0 z-40 transition-opacity bg-black opacity-50 " + (props.editAvailabilityOpen ? 'block' : 'hidden')}></div>

        <div className={ "fixed inset-y-0 right-0 z-50 flex flex-col h-screen overflow-y-auto transition duration-200 transform bg-white border-r border-gray-200 w-full sm:w-[23rem] " + (props.editAvailabilityOpen ? 'translate-x-0 ease-in' : 'translate-x-full ease-out')}>
            <div className="flex items-center justify-between px-6 mt-6 space-x-4 sm:space-x-0">
                <h3 className="text-2xl font-medium text-gray-800">Edit Availability</h3>

                <button onClick={() => props.setEditAvailabilityOpen(false)} className="text-gray-600 focus:text-primary focus:outline-none">
                    <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.5 4.5L4.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M4.5 4.5L13.5 13.5" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                </button>
            </div>

            <main className="px-6 py-4">
                <form>
                    <div>
                        <label className="text-sm font-medium text-gray-600">Availability Name </label>
                        
                        <input type="text" placeholder="Working Hours" className="block w-full p-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                    </div>

                    <div className="mt-8">
                        <label className="text-sm font-medium text-gray-600">Select Profile</label>
                        
                        <select className="block w-full py-3 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm">
                            <option>Default</option>
                            <option>test</option>
                            <option>test</option>
                            <option>test</option>
                        </select>
                    </div>

                    <div className="mt-8">
                        <label className="text-sm font-medium text-gray-600">Set your weekly hours</label>
                        
                        <label className="flex items-center mt-4 space-x-3">
                            <input checked className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Sunday</span>
                        </label>

                        <div className="flex items-center mt-4 space-x-4">
                            <input type="time" value="05:00" className="block px-3 py-2 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                            
                            <span>-</span>
                            
                            <input type="time" value="09:00" className="block px-3 py-2 mt-2 border-gray-300 rounded-md focus:ring focus:ring-opacity-40 focus:ring-blue-300 focus:border-blue-400 sm:text-sm" />
                            
                            <button type="button" className="text-gray-500 hover:text-primary">
                                <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2.25 4.5H3.75H15.75" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6 4.5V3C6 2.60218 6.15804 2.22064 6.43934 1.93934C6.72064 1.65804 7.10218 1.5 7.5 1.5H10.5C10.8978 1.5 11.2794 1.65804 11.5607 1.93934C11.842 2.22064 12 2.60218 12 3V4.5M14.25 4.5V15C14.25 15.3978 14.092 15.7794 13.8107 16.0607C13.5294 16.342 13.1478 16.5 12.75 16.5H5.25C4.85218 16.5 4.47064 16.342 4.18934 16.0607C3.90804 15.7794 3.75 15.3978 3.75 15V4.5H14.25Z" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M7.5 8.25V12.75" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M10.5 8.25V12.75" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </button>
                        </div>

                        <button type="button" className="flex items-center mt-4 space-x-2 text-gray-400 hover:text-primary">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                            <path fillRule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clipRule="evenodd" />
                            </svg>
                            
                            <span>Add new timeslot for sunday</span>
                        </button>
                    </div>

                    <hr className="my-8"/>

                    <div className="space-y-4 ">
                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Monday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Tuesday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Wednesday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Thursday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Friday</span>
                        </label>

                        <label className="flex items-center space-x-3">
                            <input className="p-2 border-gray-200 rounded-md text-primary" type="checkbox" />
                            <span>Saturday</span>
                        </label>
                    </div>

                    <div className="mt-8">
                        <h2 className="text-sm font-medium text-gray-800">Delete Availability</h2>

                        <p className="mt-2 text-sm text-gray-500">Once you delete this Availability, there is no going back.</p>

                        <button className="flex items-center px-3 py-2 mt-4 space-x-3 border rounded-md">
                            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M2 4H3.33333H14" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M5.3335 3.99998V2.66665C5.3335 2.31302 5.47397 1.97389 5.72402 1.72384C5.97407 1.47379 6.31321 1.33331 6.66683 1.33331H9.3335C9.68712 1.33331 10.0263 1.47379 10.2763 1.72384C10.5264 1.97389 10.6668 2.31302 10.6668 2.66665V3.99998M12.6668 3.99998V13.3333C12.6668 13.6869 12.5264 14.0261 12.2763 14.2761C12.0263 14.5262 11.6871 14.6666 11.3335 14.6666H4.66683C4.31321 14.6666 3.97407 14.5262 3.72402 14.2761C3.47397 14.0261 3.3335 13.6869 3.3335 13.3333V3.99998H12.6668Z" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M6.6665 7.33331V11.3333" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M9.3335 7.33331V11.3333" stroke="#E21B17" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                            <span className="text-sm font-medium text-red-primary">Delete Availability</span>
                        </button>
                    </div>
                    
                    <div className="flex items-center justify-end mt-8 space-x-4">
                        <button className="w-1/2 px-4 py-2 text-sm font-medium text-center text-gray-600 uppercase transition-colors duration-200 transform border border-gray-700 rounded-md hover:bg-white">
                            Cancel
                        </button>
                        <button className="w-1/2 px-4 py-2 text-sm font-medium text-center text-white uppercase transition-colors duration-200 transform rounded-md bg-primary hover:bg-opacity-80">
                            Save Changes
                        </button>
                    </div>
                </form>
            </main>
        </div>
    </div>
  );
}

export default editAvailabilityOpen;
